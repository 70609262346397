import React, { useState, useEffect } from "react";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { IoIosCloseCircleOutline } from "react-icons/io";
import GHeader from "../../components/GeneralHeader/GHeader";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircle,
  IoIosAddCircleOutline
} from "react-icons/io";
import {
  Button,
  notification,
  Select,
  Spin,
  Table,
  Flex,
  DatePicker,
  Space
} from "antd";
import { useLocation } from "react-router-dom";
import {
  createStudentPlanner,
  getAllSubjects,
  getPlannerDetails
} from "../../store/studentPlannerDetails";
import { useAppSelector } from "../../store/hooks";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { fetchStudent } from "../../store/services/PlannerModal";
import avtar from "../../assets/attachment.png";

interface PlannerData {
  key: string;
  plannerId?: number;
  day: string;
  subject: string;
  work: string;
  dueDate: string;
  timeSpent?: string;
  signature?: string;
  delete?: boolean;
  id?: number;
  status: number;
  score?: number;
  teachersComments?: string;
  parentsComments?: string;
}
interface singleStudent {
  classId: number;
  className: string;
  firstName: string;
  imageUrl: string;
  lastName: string;
  salutation: string;
  studentId?: number;
  id?: number;
}

const StudentPlanner: React.FC = () => {
  const location = useLocation();
  const [data, setData] = useState<PlannerData[]>([]);
  const [editedRow, setEditedRow] = useState<PlannerData[]>([]);
  const [studentDetails, setStudentDetails] = useState<singleStudent>();
  const [subject, setSubject] = useState([]);
  const [students, setStudents] = useState([]);
  const [load, setLoad] = useState(false);
  const [week, setWeek] = useState<null | string>("Today");
  const today = moment().startOf("isoWeek").format("YYYY-MM-DD");
  const [date, setDate] = useState<any>();
  const dayOrder = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];

  let lastDay: string | null = null;

  const { loading } = useAppSelector((state) => state.plan);
  const { user } = useAppSelector((state) => state.auth);
  const role = user?.role;
  const capitalizeFirstLetter = (str = "") => {
    return str
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace(/\B\w/g, (char) => char.toLowerCase());
  };

  const columns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
      render: (text: string, record: PlannerData) => {
        const showDay = text !== lastDay;
        lastDay = text;
        const dayText =
          role === "student" && (text === undefined || text === "")
            ? "----"
            : text
            ? text.charAt(0).toUpperCase() + text.slice(1)
            : "----";
        return showDay ? (
          <div className="days std_left">
            <div className="std_width">
              <p>{dayText}</p>
            </div>
            {role === "teacher" && (
              <IoIosAddCircleOutline
                onClick={() => addColumns(text)}
                fontSize={"20px"}
                cursor={"pointer"}
              />
            )}
          </div>
        ) : (
          <div className="std_left">
            {role === "teacher" && (
              <IoIosCloseCircleOutline
                onClick={() => onRemove(record.key)}
                fontSize={"22px"}
                cursor={"pointer"}
              />
            )}
          </div>
        );
      }
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text: string, record: PlannerData) => (
        <div className="std_left" style={{ width: "100px" }}>
          {role === "teacher" ? (
            <Select
              style={{ height: "40px", width: "100%" }}
              value={text || "----"}
              onChange={(e) => handleUpdatedSubject(e, record.key)}
              placeholder="Select Subjects"
            >
              {subject?.length > 0 &&
                subject?.map((item: any) => (
                  <Select.Option
                    key={item.subjectName}
                    value={item.subjectName}
                  >
                    {item.subjectName}
                  </Select.Option>
                ))}
            </Select>
          ) : (
            <div>{text || "----"}</div>
          )}
        </div>
      )
    },
    // {
    //   title: "Teacher Comment",
    //   dataIndex: "teacherComment",
    //   key: "teacherComment",
    //   render: (text: string, record: PlannerData) => (
    //     <div className="std_left">
    //       {role === "teacher" ? (
    //         <input
    //           type="text"
    //           value={text || ""}
    //           // onChange={(e) => handleWorkChange(e.target.value, record.key)}
    //           placeholder="Place a comment"
    //           className="subject-input"
    //         />
    //       ) : (
    //         <div>{text || "----"}</div>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Student Comment",
    //   dataIndex: "studentComment",
    //   key: "studentComment",
    //   render: (text: string, record: PlannerData) => (
    //     <div className="std_left">
    //       {role === "student" ? (
    //         <input
    //           type="text"
    //           value={text || ""}
    //           // onChange={(e) => handleWorkChange(e.target.value, record.key)}
    //           placeholder="Place a comment"
    //           className="subject-input"
    //         />
    //       ) : (
    //         <p style={{ minWidth: "100px" }}> {text || "----"}</p>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Score",
    //   dataIndex: "score",
    //   key: "score",
    //   render: (text: string, record: PlannerData) => (
    //     <div className="std_left">
    //       {role === "teacher" ? (
    //         <input
    //           type="number"
    //           value={text || ""}
    //           // onChange={(e) => handleWorkChange(e.target.value, record.key)}
    //           placeholder="Score"
    //           className="subject-input"
    //           style={{ width: "70px" }}
    //         />
    //       ) : (
    //         <div>{text || "----"}</div>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "Work",
      dataIndex: "work",
      key: "work",
      render: (text: string, record: PlannerData) => (
        <div className="std_left">
          {role === "teacher" ? (
            <input
              type="text"
              value={text || ""}
              onChange={(e) => handleWorkChange(e.target.value, record.key)}
              placeholder="Some text here..."
              className="subject-input"
            />
          ) : (
            <div>{text || "----"}</div>
          )}
        </div>
      )
    },
    {
      title: "Teachers Comments",
      dataIndex: "teachersComments",
      key: "teachersComments",
      render: (text: string | undefined, record: PlannerData) =>(
          <div className="std_left">
          {role === "teacher" || role === 'admin' ? (
            // record.subject && record.work ? (
              <input
                type="text"
                value={text !== "undefined" ? text : ""} // Ensure empty string for input field
                onChange={(e) =>
                  handleTeachersComments(e.target.value, record.key)
                }
                placeholder="Comments"
                className="subject-input"
              />
            // ) 
            // : (
            //   "---"
            // )
          ) : (
            <div>{text === "undefined" || text === "" || text === null ? "----" : text}</div>
          )}
        </div>
      )
    },
    {
      title: "Parents Comments",
      dataIndex: "parentsComments",
      key: "parentsComments",
      render: (text: string | undefined, record: PlannerData) =>
        //(
        {
          return (
            <div className="std_left">
              {role === "student" || role === 'admin' ?  (
                // record.subject && record.work ? (
                  <input
                    type="text"
                    value={text !== "undefined" ? text : ""} // Ensure empty string for input field
                    onChange={(e) =>
                      handleParentsComments(e.target.value, record.key)
                    }
                    placeholder="Comments"
                    className="subject-input"
                  />
                // ) : (
                //   "---"
                // )
              ) : (
                <div>{text === "undefined" || text !== "" ? "----" : text}</div>
              )}
            </div>
          );
        }

      //)
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text: string, record: PlannerData) => {
        const formattedDate =
          text && !isNaN(Date.parse(text))
            ? new Date(text).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric"
              })
            : "----";

        return (
          <div className="std_left">
            {role === "teacher" ? (
              <input
                type="date"
                value={text ? new Date(text).toISOString().split("T")[0] : ""}
                onChange={(e) => handleDateChange(e.target.value, record.key)}
                placeholder="Select Date"
                className="subject-input"
              />
            ) : (
              <div>{formattedDate}</div>
            )}
          </div>
        );
      }
    },
    {
      title: "Time Spent",
      dataIndex: "timeSpent",
      key: "timeSpent",
      render: (text: string | undefined, record: PlannerData) => (
        <div className="std_left">
          {role === "student" ? (
            // record.subject && record.work ? (
              <input
                type="text"
                value={text !== "undefined" ? text : ""}
                onChange={(e) => handleTimeSpent(e.target.value, record.key)}
                placeholder="Time Spent"
                className="subject-input"
              />
            // ) : (
            //   "---"
            // )
          ) : (
            <div>{text !== "undefined" && text !== "" ? text : "----"}</div>
          )}
        </div>
      )
    },
    {
      title: "Signature",
      dataIndex: "signature",
      key: "signature",
      render: (text: string | undefined, record: PlannerData) => (
        <div className="std_left">
          {role === "student" ? (
            // record.subject && record.work ? (
              <input
                type="text"
                value={text !== "undefined" ? text : ""} // Ensure empty string for input field
                onChange={(e) =>
                  handleSignatureChange(e.target.value, record.key)
                }
                placeholder="Signature"
                className="subject-input"
              />
            // ) : (
            //   "---"
            // )
          ) : (
            <div>{text !== "undefined" && text !== "" ? text : "----"}</div>
          )}
        </div>
      )
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (text: string | undefined, record: PlannerData) => (
        <div className="std_left">
          {role === "teacher" ? (
            // record.subject && record.work ? (
              <input
                type="text"
                value={text !== "undefined" ? text : ""} // Ensure empty string for input field
                onChange={(e) =>
                  handleScoreChange(Number(e.target.value), record.key)
                }
                placeholder="Score"
                className="subject-input"
              />
            // ) : (
            //   "---"
            // )
          ) : (
            <div>{text !== "undefined" && text !== "" ? text : "----"}</div>
          )}
        </div>
      )
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: PlannerData) => {
       
        return (
          <div className="check_planner std_left">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <IoIosCheckmarkCircleOutline
                className={record.status === 0 ? "std_disabled" : ""}
                color="green"
                fontSize={"20px"}
                cursor={"pointer"}
                onClick={() => handleStatusChange(record.key, 1)}
              />
              <IoIosCloseCircle
                color="red"
                className={record.status === 1 ? "std_disabled" : ""}
                fontSize={"20px"}
                cursor={"pointer"}
                onClick={() => handleStatusChange(record.key, 0)}
              />
            </div>
          </div>
        );
      }
    }
  ];

  const addColumns = (day: string) => {
    const isCurrentRowFilled = data.every((item: PlannerData) => {
      if (item.day === day) {
        return item.subject !== "" && item.work !== "" && item.dueDate !== "";
      }
      return true;
    });

    if (!isCurrentRowFilled) {
      notification.error({
        message: "You have to fill the current row before adding new columns"
      });
      return;
    }

    const newData = [
      ...data,
      {
        key: uuidv4(),
        day: day,
        work: "",
        subject: "",
        dueDate: "",
        status: 3
      }
    ];

    const sortedNewData = newData.sort(
      (a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day)
    );
    setData(sortedNewData);
  };

  const handleTimeSpent = (val: string, key: string) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, timeSpent: val } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            timeSpent: val
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };

  const handleSignatureChange = (val: string, key: string) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, signature: val } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            signature: val
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };
  const handleScoreChange = (val: number, key: string) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, score: val } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            score: val
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };

  const handleDateChange = (val: string, key: string, option?: any) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, dueDate: val } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            dueDate: val
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };

  const handleUpdatedSubject = (newSubject: string, key: string) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, subject: newSubject } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            subject: newSubject
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };

  const handleWorkChange = (val: string, key: string) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, work: val } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = { ...updatedEditedRow[index], work: val };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };

  const handleStatusChange = (key: string, status: number) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, status: status } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            status: status
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };
  const handleParentsComments = (val: string, key: string) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, parentsComments: val } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            parentsComments: val
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };
  const handleTeachersComments = (val: string, key: string) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, teachersComments: val } : item
      );

      setEditedRow((prev) => {
        const index = prev.findIndex((item) => item.key === key);
        if (index > -1) {
          const updatedEditedRow = [...prev];
          updatedEditedRow[index] = {
            ...updatedEditedRow[index],
            teachersComments: val
          };
          return updatedEditedRow;
        } else {
          return [...prev, updatedData.find((item) => item.key === key)!];
        }
      });

      return updatedData;
    });
  };
  const onRemove = (key: string) => {
    setData((prevData) => {
      const rowToRemove = prevData.find((item) => item.key === key);

      if (!rowToRemove) return prevData;

      const updatedData = prevData.filter((item) => item.key !== key);

      setEditedRow((prevEditedRow) => {
        const existingRowIndex = prevEditedRow.findIndex(
          (item) => item.key === key
        );

        if (existingRowIndex > -1) {
          const updatedEditedRow = [...prevEditedRow];
          updatedEditedRow[existingRowIndex] = {
            ...updatedEditedRow[existingRowIndex],
            delete: true
          };
          return updatedEditedRow;
        } else {
          return [...prevEditedRow, { ...rowToRemove, delete: true }];
        }
      });

      return updatedData;
    });
  };

  const getPlan = async (mainDate?: any) => {
    try {
      const res = await getPlannerDetails({
        studentId: studentDetails?.studentId ?? user?.id,
        date: mainDate ? mainDate.format("YYYY-MM-DD") : today
        // date: mainDate ? mainDate : week === "Today" ? today : lastWeek
      });
      if (res && res.data && Array.isArray(res.data.planner)) {
        const fetchedData: PlannerData[] = res.data.planner.map(
          (item: any) => ({
            ...item,
            key: uuidv4()
          })
        );

        const initialData: PlannerData[] = dayOrder.flatMap((day) => {
          const entriesForDay = fetchedData.filter((item) => item.day === day);

          return entriesForDay.length > 0
            ? entriesForDay
            : [
                {
                  key: uuidv4(),
                  day,
                  subject: "",
                  work: "",
                  dueDate: "",
                  status: 0
                }
              ];
        });

        const sortedData = initialData.sort(
          (a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day)
        );

        setData(sortedData);
      } else {
        console.error("Planner data is not in the expected format", res);
      }
    } catch (error) {
      console.error("Failed to fetch planner details:", error);
    }
  };
  const getDay = (e: any) => {
    setWeek(e);
  };
  const getSubjectsAll = async () => {
    try {
      if (studentDetails?.classId) {
        if (role === "teacher") {
          const res: any = await getAllSubjects({
            classId: studentDetails?.classId
          });
          setSubject(res?.data?.subjects);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const submitData = async () => {
    setLoad(true);
    try {
      if (editedRow.length === 0) {
        notification.error({ message: "Empty fields are not allowed" });
        return;
      }
      const modifyData = editedRow.map(
        ({
          day,
          subject,
          work,
          dueDate,
          id,
          delete: deleteFlag,
          status,
          plannerId,
          timeSpent,
          signature,
          score,
          teachersComments,
          parentsComments
        }: PlannerData) => {
          const newItem: any = {
            day,
            subject,
            work,
            dueDate,
            createdDate: date,
            studentId: Number(studentDetails?.studentId || studentDetails?.id),
            timeSpent,
            signature,
            id,
            score,
            teachersComments,
            parentsComments
          };

          if (plannerId) {
            newItem.id = plannerId;
          }
          if (status) {
            newItem.status = status;
          }
          if (deleteFlag) {
            newItem.delete = deleteFlag;
          }

          return newItem;
        }
      );

      // if (role === "teacher") {
      //   const hasEmpty = modifyData.some((item) => {
      //     return !item.subject || !item.work || !item.dueDate;
      //   });

      //   // if (hasEmpty) {
      //   //   notification.error({
      //   //     message: "All fields must be filled before submitting.",
      //   //   });
      //   //   return;
      //   // }
      // }

      const saveData = {
        planners: modifyData,
        date: week === "Today" ? today : week
      };

      const res = await createStudentPlanner(saveData);

      if (res?.status === 200) {
        getPlan(date);
        setEditedRow([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getSubjectsAll();
  }, [studentDetails]);

  // useEffect(() => {
  //     getPlan();
  // }, [studentDetails?.studentId]);

  useEffect(() => {
    if (date) {
      getPlan(date);
    }
  }, [date]);

  useEffect(() => {
    if (location.state) {
      setStudentDetails(location.state);
    } else {
      if (user) {
        //@ts-ignore
        setStudentDetails(user);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (studentDetails?.classId) {
          const res = await fetchStudent({ classId: studentDetails?.classId });
          setStudents(res?.data.students);
        }
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, [studentDetails]);

  const getItemValue = (e: any) => {
    setDate(null);
    const findStudent = students.find((item: any) => {
      return item.studentId === e;
    });
    setStudentDetails(findStudent);
  };

  const { RangePicker }: any = DatePicker;

  return checkRoutePermissions("studentPlanner") ? (
    <div className="mainDiv responsive">
      <div className="main_parent">
        <div>
          <GHeader heading="Student Planner" />
          <div className="main_Std_parent">
            <div className="std_main">
              <div className="std_header">
                <img
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "50%" }}
                  src={
                    studentDetails?.imageUrl ? studentDetails?.imageUrl : avtar
                  }
                  alt="student"
                />
              </div>
              <div className="std_child">
                <p className="std_p">
                  {capitalizeFirstLetter(studentDetails?.firstName)}{" "}
                  {capitalizeFirstLetter(studentDetails?.lastName)}
                </p>
                <div className="std_span">
                  <span className="span_child">
                    {studentDetails?.className}
                  </span>
                  {/* <span className="std_dot"></span>
                                    <span className="span_child">4.02.24</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="std_select">
            <div className="planner_top">
              <p>Week Commencing</p>
            </div>
            <div
              className={`${
                role === "teacher"
                  ? "select_planner_parent"
                  : "select_planner_parent_single"
              }`}
            >
              {role === "teacher" && (
                <Select
                  placeholder="Select Student"
                  value={
                    studentDetails?.firstName + " " + studentDetails?.lastName
                  }
                  onChange={(e) => {
                    getItemValue(e);
                  }}
                  className={"plan_details_select"}
                >
                  {students?.length > 0 &&
                    students.map((item: any) => {
                      return (
                        <Select.Option value={item.studentId}>
                          {item.firstName} {item.lastName}
                        </Select.Option>
                      );
                    })}
                </Select>
              )}

              <Space direction="vertical">
                {/* @ts-ignore */}
                <DatePicker
                  disabledDate={(current) => {
                    return current && current.day() !== 1;
                  }}
                  value={date}
                  allowClear={false}
                  onChange={(e: any, option: any) => {
                    setDate(e);
                    getDay(option);
                    setEditedRow([]);
                  }}
                />
              </Space>
            </div>
          </div>
        </div>
        {loading ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: "50vh" }}
            gap="middle"
          >
            <Spin size="large" />
          </Flex>
        ) : (
          <div className="mian_tab">
            <div className="table_container" style={{ width: "100%" }}>
              <Table
                className="custom_table_design"
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{ x: 600 }}
                pagination={false}
                rowKey="key"
              />
            </div>
          </div>
        )}

        <div className="main_pagination">
          <Button
            onClick={submitData}
            disabled={load}
            loading={load}
            className="btn_planner"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default StudentPlanner;
