import { Card, Col, Tag, Tooltip, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

interface cardProps {
  cardName: string;
  graphImage: string;
  data?: any;
  type: string;
  graphArrow: string;
  totalStudents?: any;
  percent?: any;
}

const ReportCard: React.FC<cardProps> = ({
  cardName,
  graphImage,
  type,
  graphArrow,
  data,
  totalStudents,
  percent
}) => {
  const [dataArray, setDataArray] = useState(data);

  const className = dataArray?.[0]?.className
    ? dataArray?.[0].className
    : "Unknown Class";

  const totalProgress = percent ? percent : 0;

  const studentCount = totalStudents ? totalStudents : dataArray ? dataArray?.length : 0;
    
    let studentNames = dataArray ? Array.from(
      new Set(dataArray.map((item?:any) => item.studentId))
    ).map(studentId => {
      let stName = dataArray.find((item?:any)=> item.studentId === studentId);
      stName = stName?.studentName ? stName?.studentName :`${stName?.firstName} ${stName?.lastName}` 
      return stName ;
    }).join(", ") : "";
    
  useEffect(() => {
    setDataArray(data);
  }, [data]);

  return dataArray ? (
    <Col xs={24} sm={12} lg={12}>
      <Card className="custom_card">
        <div className="cardupdiv">
          <img src={graphImage} alt="graph" />
          <h1>{cardName}</h1>
        </div>
        <div className="classlabel">
          <p className="classtext">{className}</p>
        </div>
        <div className="carddowndiv">
          {/* Tooltip for student names */}
          <Tooltip title={studentNames ? studentNames : "No students"}>
            <h1>{studentCount} Students</h1>
          </Tooltip>
          <Tag
            icon={
              graphArrow === "down" ? (
                <ArrowDownOutlined />
              ) : (
                <ArrowUpOutlined />
              )
            }
            color={type}
            style={{ borderRadius: "16px", padding: "4px" }}
          >
            {totalProgress}%
          </Tag>
        </div>
      </Card>
    </Col>
  ) : (
    <Col xs={24} sm={12} lg={12}>
      <Card className="custom_card">
        <div className="cardupdiv">
          <Skeleton loading={true} active></Skeleton>
        </div>
      </Card>
    </Col>
  );
};

export default ReportCard;
