import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Progress,
  Select,
  Table,
  Modal,
  Form,
  Space,
} from "antd";

import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { IoTrashOutline } from "react-icons/io5";

import userIcon from "../../../src/assets/user-icon.png";

// @ts-ignore
import addstudent from "../../assets/addstudent.png";

import CustomPagination from "../../components/Pagination/CustomPagination";
import { getSubjectListByClassDropdown } from "../../store/services/subject";
import { getTopFerformer } from "../../store/services/state";
import useDebounce from "../../Hook/useDebounce";
import { useAppSelector } from "../../store/hooks";
import { getLevelBySubject } from "../../store/services/class";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLevels,
  setSubjectListByClass,
} from "../../store/slices/Classes/classSlice";

interface FilterType {
  search: string | null;
  classId: null | string;
  subjectId: null | string;
  levelId: null | string;
}

const initialFilter = {
  search: null,
  classId: null,
  subjectId: null,
  levelId: null,
};

const TopPerformers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const { classes, subjectListByClass, levels } = useAppSelector(
    (state) => state.class
  );
  const { topForformer, loading } = useAppSelector((state) => state.states);
  const [filterState, setFilterState] = useState<FilterType>(initialFilter);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSubjectListByClass([]));
      dispatch(setLevels([]));
      currentPage !== 1 && setCurrentPage(1);
      setFilterState(initialFilter);
    };
  }, []);

  const handleClassChange = async (e: any) => {
    currentPage !== 1 && setCurrentPage(1);
    subjectListByClass?.length > 0 && dispatch(setSubjectListByClass([]));
    levels?.length > 0 && dispatch(setLevels([]));
    setFilterState({
      ...filterState,
      classId: e,
      subjectId: null,
      levelId: null,
    });
    await getSubjectListByClassDropdown({ classId: e === "all" ? "" : e });
  };

  const handleSubjectChange = async (e: any) => {
    currentPage !== 1 && setCurrentPage(1);
    levels?.length > 0 && dispatch(setLevels([]));
    setFilterState({
      ...filterState,
      subjectId: e,
      levelId: null,
    });
    await getLevelBySubject({ subjectId: e === "all" ? null : e });
  };

  const handleLevelChange = async (e: any) => {
    currentPage !== 1 && setCurrentPage(1);
    setFilterState({
      ...filterState,
      levelId: e,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();

  const _getTopPerformer = async () => {
    const payload = {
      search: filterState?.search,
      classId: filterState.classId === "all" ? "" : filterState.classId,
      subjectId: filterState.subjectId === "all" ? null : filterState.subjectId,
      levelId: filterState.levelId === "all" ? "" : filterState.levelId,
      page: currentPage,
      perPage,
    };
    const response: any = await getTopFerformer(payload);
    setCount(response?.totalCount);
  };

  const handleReset = () => {
    dispatch(setSubjectListByClass([]));
    dispatch(setLevels([]));
    currentPage !== 1 && setCurrentPage(1);
    setFilterState(initialFilter);
  };

  useEffect(() => {
    _getTopPerformer();
  }, [filterState, currentPage]);

  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "15%",
      render: (a: any, b: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={b?.avatar ? b?.avatar : userIcon}
              alt="avatar"
              style={{ width: "40px", height: "40px" }}
            />
            <p
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => {
                navigate("/student-report", {
                  state: { id: b?.studentId },
                });
              }}
            >
              {b.firstName}
            </p>
          </div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: "10%"
    },
    {
      title: "Class",
      dataIndex: "classIndex",
      key: "class",
      width: "10%",
      render(a: any, b: any) {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p style={{ fontSize: "14px" }}>{b.className}</p>
          </div>
        );
      },
    },
    {
      title: "Subjects",
      dataIndex: "subjectName",
      key: "subjects",
      width: "10%",
    },
    {
      title: "Starting level",
      dataIndex: "startingLevel",
      key: "startingLevel",
      width: "10%",
    },
    {
      title: "Starting Progress",
      dataIndex: "startingprogress",
      key: "startingprogress",
      width: "15%",
      render: (a: any, b: any) => {
        return (
          <div>
            <Progress
              percent={Number(Number(b.startingprogress)?.toFixed(0))}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    
    {
      title: "At level",
      dataIndex: "levelName",
      key: "levelname",
      width: "10%",
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: "15%",
      render: (a: any, b: any) => {
        return (
          <div>
            <Progress
              percent={Number(Number(b.percentage)?.toFixed(0))}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    // {
    //   title: " ",
    //   dataIndex: "actions",
    //   key: "actions",
    //   width: "10%",
    //   render: () => {
    //     return (
    //       <div
    //         style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
    //       >
    //         <IoTrashOutline
    //           style={{
    //             width: "20px",
    //             height: "20px",
    //             color: "#D92D20",
    //             cursor: "not-allowed",
    //             opacity: 0.5,
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <div className="teachers_container">
      <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
      >
        <img src={addstudent} alt="logo" />
        <div className="modalheading">
          <h1>Add Student</h1>
          <div className="pdiv">
            <p className="modoalp">
              Fill out the following information to add a new student to the
              system.
            </p>
          </div>
        </div>
        <div className="form">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name="name" label="Fist Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="last name" label="Last Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="class" label="Select Class" className="labels">
              <Select
                // className='filter_select'
                placeholder="Class 1"
                optionFilterProp="children"
                onChange={() => {}}
                suffixIcon={
                  <RiExpandUpDownLine
                    style={{ width: "20px", height: "20px", color: "#667085" }}
                  />
                }
                options={[
                  {
                    value: "1",
                    label: "Class 1",
                  },
                  {
                    value: "2",
                    label: "Class 2",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Progress percent={60} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button className="cancelbtn">Cancel</Button>
                <Button htmlType="reset" className="addbtn">
                  Add
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <div className="fliters_container">
        <Input
          placeholder="Search..."
          prefix={
            <CiSearch
              style={{ width: "20px", height: "20px", color: "#667085" }}
            />
          }
          className="filter_search"
          onChange={(e) => debouncedFunction(e.target.value, "search")}
        />

        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a class"
            value={filterState?.classId}
            onChange={handleClassChange}
            optionFilterProp="children"
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
            options={[
              { value: "all", label: "All Classes" },
              ...classes?.map((value: any) => {
                return {
                  value: value.classId,
                  label: value.className,
                };
              }),
            ]}
          />
        </div>
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a subject"
            value={filterState?.subjectId}
            disabled={subjectListByClass?.length === 0}
            onChange={handleSubjectChange}
            optionFilterProp="children"
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
            options={[
              { value: "all", label: "All Subjects" },
              ...subjectListByClass?.map((value: any) => {
                return {
                  value: value.subjectId,
                  label: value.subjectName,
                };
              }),
            ]}
          />
        </div>
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a level"
            disabled={levels?.length === 0}
            value={filterState?.levelId}
            onChange={handleLevelChange}
            optionFilterProp="children"
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
            options={[
              { value: "all", label: "All Levels" },
              ...levels?.map((value: any) => {
                return {
                  value: value.levelId,
                  label: value.levelName,
                };
              }),
            ]}
          />
        </div>
        {/* <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Current year"
            optionFilterProp="children"
            onChange={(e) => {
              setFilterState({ ...filterState, year: e });
            }}
            suffixIcon={
              <RiExpandUpDownLine
                style={{ width: "20px", height: "20px", color: "#667085" }}
              />
            }
            value={filterState.year}
          >
            {dropdownYears?.length > 0 &&
              dropdownYears.map((item: any) => {
                return <Select.Option value={item}>{item}</Select.Option>;
              })}
          </Select>
        </div> */}
        <div className="select_container">
          <Button
            className="btn_clear"
            style={{ height: "47px" }}
            onClick={handleReset}
          >
            Clear
          </Button>
        </div>
      </div>

      <div className="table_container">
        {topForformer && (
          <Table
            className="custom_table_design"
            dataSource={topForformer}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        )}
      </div>
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={count}
      />
    </div>
  );
};

export default TopPerformers;
