import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Progress,
  Select,
  Table,
  Modal,
  Form,
  Space,
} from "antd";

import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { IoTrashOutline } from "react-icons/io5";

// @ts-ignore
import addstudent from "../../assets/addstudent.png";

import CustomPagination from "../../components/Pagination/CustomPagination";
import { useAppSelector } from "../../store/hooks";
import { getAboveTarget } from "../../store/services/state";

import moulviAvatar from "../../../src/assets/user-icon.png";
import useDebounce from "../../Hook/useDebounce";
import { setSubjectListByClass } from "../../store/slices/Classes/classSlice";
import { useDispatch } from "react-redux";
import { getSubjectListByClassDropdown } from "../../store/services/subject";
import { getLevelBySubject } from "../../store/services/class";
import { useNavigate } from "react-router-dom";

const initialFilter = {
  search: null,
  classId: null,
  subjectId: null,
};

const StudentAboveTarget = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { classes, subjectListByClass } = useAppSelector(
    (state) => state.class
  );
  const { aboveTarget, loading } = useAppSelector((state) => state.states);
  const [filterState, setFilterState] = useState(initialFilter);
    const navigate = useNavigate();
    
    const columns = [
    {
      title: "Name",
      dataIndex: "studentName",
      key: "name",
      width: "10%",
      render: (a: any, b: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={b?.avatar ? b?.avatar : moulviAvatar}
              alt="avatar"
              style={{ width: "40px", height: "40px" }}
            />
            <p
             onClick={() => {
              navigate("/student-report", {
                state: { id: b?.studentId },
              });
            }} 
            style={{ fontSize: "14px" ,cursor:'pointer'}}>{b?.firstName}</p>
          </div>
        );
      },
    },
    {
      title: "Class",
      dataIndex: "className",
      key: "className",
      width: "10%",
      render(a: any) {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p style={{ fontSize: "14px" }}>{a}</p>
          </div>
        );
      },
    },
    {
      title: "Subjects",
      dataIndex: "subjectName",
      key: "subject",
      width: "10%",
    },
    {
      title: "Starting Level",
      dataIndex: "startingLevel",
      key: "startingLevel",
      width: "10%",
    },
    {
      title: "Starting Progress",
      dataIndex: "startingprogress",
      key: "startingprogress",
      width: "15%",
      render: (a: any, b: any) => {
        return (
          <div>
            <Progress
              percent={Number(Number(b.startingprogress)?.toFixed(0))}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    {
      title: "At Level",
      dataIndex: "levelName",
      key: "levelName",
      width: "10%",
    },
    {
      title: "Progress",
      dataIndex: "percentage",
      key: "percentage",
      width: "15%",
      render: (a: any, b: any) => {
        return (
          <div>
            <Progress
              percent={Number(Number(b.percentage)?.toFixed(0))}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    {
      title: "Target Level",
      dataIndex: "targetedLevel",
      key: "targetedLevel",
      width: "7%",
    },
    {
      title: "Target %",
      dataIndex: "targetPercentage",
      key: "targetPercentage",
      width: "3%",
      render(a: any) {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p style={{ fontSize: "14px" }}>{a}%</p>
          </div>
        );
      },
    },
  
    // {
    //   title: " ",
    //   dataIndex: "actions",
    //   key: "actions",
    //   width: "10%",
    //   render: () => {
    //     return (
    //       <div
    //         style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
    //       >
    //         {/* <FiEdit2 style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> */}
    //         <IoTrashOutline
    //           style={{
    //             width: "20px",
    //             height: "20px",
    //             color: "#D92D20",
    //             cursor: "not-allowed",
    //             opacity: 0.5,
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];
  
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setSubjectListByClass([]));
      currentPage !== 1 && setCurrentPage(1);
      setFilterState(initialFilter);
    };
  }, []);

  const handleClassChange = async (e: any) => {
    currentPage !== 1 && setCurrentPage(1);
    subjectListByClass?.length > 0 && dispatch(setSubjectListByClass([]));
    setFilterState({
      ...filterState,
      classId: e,
      subjectId: null,
    });
    await getSubjectListByClassDropdown({ classId: e === "all" ? "" : e });
  };

  const handleSubjectChange = async (e: any) => {
    currentPage !== 1 && setCurrentPage(1);
    setFilterState({
      ...filterState,
      subjectId: e,
    });
    await getLevelBySubject({ subjectId: e === "all" ? null : e });
  };

  useEffect(() => {
    getAboveTargetStudent();
  }, [filterState]);

  const getAboveTargetStudent = async () => {
    const payload = {
      search: filterState?.search,
      classId: filterState.classId === "all" ? "" : filterState.classId,
      subjectId: filterState.subjectId === "all" ? null : filterState.subjectId,
    };
    let res =  await getAboveTarget(payload);
  };

  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);
  const [form] = Form.useForm();

  return (
    <div className="teachers_container">
      <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
      >
        <img src={addstudent} alt="logo" />
        <div className="modalheading">
          <h1>Add Student</h1>
          <div className="pdiv">
            <p className="modoalp">
              Fill out the following information to add a new student to the
              system.
            </p>
          </div>
        </div>
        <div className="form">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name="name" label="Fist Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="last name" label="Last Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="class" label="Select Class" className="labels">
              <Select
                // className='filter_select'
                placeholder="Class 1"
                optionFilterProp="children"
                onChange={() => {}}
                suffixIcon={
                  <RiExpandUpDownLine
                    style={{ width: "20px", height: "20px", color: "#667085" }}
                  />
                }
                options={[
                  {
                    value: "1",
                    label: "Class 1",
                  },
                  {
                    value: "2",
                    label: "Class 2",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Progress percent={60} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button className="cancelbtn">Cancel</Button>
                <Button htmlType="reset" className="addbtn">
                  Add
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <div className="fliters_container">
        <Input
          placeholder="Search..."
          prefix={
            <CiSearch
              style={{ width: "20px", height: "20px", color: "#667085" }}
            />
          }
          className="filter_search"
          onChange={(e) => debouncedFunction(e.target.value, "search")}
        />
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a class"
            value={filterState?.classId}
            onChange={handleClassChange}
            optionFilterProp="children"
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
            options={[
              { value: "all", label: "All Classes" },
              ...classes?.map((value: any) => {
                return {
                  value: value.classId,
                  label: value.className,
                };
              }),
            ]}
          />
        </div>
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a subject"
            value={filterState?.subjectId}
            disabled={subjectListByClass?.length === 0}
            onChange={handleSubjectChange}
            optionFilterProp="children"
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
            options={[
              { value: "all", label: "All Subjects" },
              ...subjectListByClass?.map((value: any) => {
                return {
                  value: value.subjectId,
                  label: value.subjectName,
                };
              }),
            ]}
          />
        </div>
      </div>

      <div className="table_container">
        <Table
          className="custom_table_design"
          dataSource={aboveTarget?.slice(currentPage * 8 - 8, currentPage * 8)}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </div>
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={aboveTarget?.length}
      />
    </div>
  );
};

export default StudentAboveTarget;
