import React, { useEffect, useState } from "react";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { AiOutlineHome } from "react-icons/ai";
import "../../styles/pages/studentReport.css";
import { Avatar, Select, Spin } from "antd";
import avatarStd from "../../assets/avatar_student.png";
import ReactSpeedometer from "react-d3-speedometer";
import SpeedoMeter from "../../components/speedoMeter/SpeedoMeter";
import SubjectLevel from "../../components/subjectLevel/SubjectLevel";
import TargetToReach from "../../components/targetToReach/TargetToReach";
import HistoricalLevel from "../../components/HistoricalLevel/HistoricalLevel";
import StudentProfile from "../../components/studentProfile/StudentProfile";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getOverAllProgress,
  getStudentCurrentLevel,
  getStudentReport,
  getStudentReportsById,
  getStudentSubjectById,
  getStudentUnArchiveTarget
} from "../../store/services/report";
import { useAppSelector } from "../../store/hooks";
import GeneratorCard from "../../components/Modals/generatorCard/generatorCard";
import moment from "moment";
import { getStudentSubjects } from "../../store/services/class";
import {
  getAllSubject,
  getSubjectCurrentLevel
} from "../../store/services/subject";
import { getLevelProgress } from "../../store/services/learning";
import avtar from "../../assets/attachment.png";
import NeedleMeterChart from "../../components/Chart/NeedleMeterChart";
import CustomPagination from "../../components/Pagination/CustomPagination";

const StudentReport = () => {
  const location = useLocation();
  const reportId = location?.state?.reportId;
  const StudentId = location?.state?.studentId;
  const { user } = useAppSelector((state) => state?.auth);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingtarget, setLoadingTarget] = useState<boolean>(false);
  const [reportInfo, setReportInfo] = useState<any>(null);
  const [subjects, setSubjects] = useState<any>(null);
  const [activeItem, setActiveItem] = useState({
    subjectId: null,
    activeTab: "All subjects"
  });
  const [currentProgress, setCurrentProgress] = useState<any>(null);
  const [currentTarget, setCurrentTarget] = useState<any>([]);
  const [reportList, setReportList] = useState<any>(null);
  const [changeReport, setChangeReport] = useState<any>(null);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [allProgress, setAllProgress] = useState<any>(null);
  const itemsPerPage = 10;

  useEffect(() => {
    if (reportId) {
      getReport(reportId);
    }
  }, [reportId]);
  useEffect(() => {
    if (changeReport) {
      getReport(changeReport);
    }
  }, [changeReport]);

  // useEffect(() => {
  //   if (selectedSubject) {
  //     getSubjectLevel(selectedSubject);
  //     setCurrentPage(1);
  //   }
  // }, [selectedSubject, reportInfo]);

  useEffect(() => {
    if (activeItem !== null && reportInfo) {
      getStudentLevel();
      setCurrentPage(1);
    }
  }, [activeItem, reportInfo]);
  useEffect(() => {
    if (activeItem !== null && reportInfo && currentProgress) {
      getStudentUnArchive();
    }
  }, [activeItem, currentProgress, reportInfo]);

  useEffect(() => {
    const getSubjects = async () => {
      const response = await getStudentSubjectById({
        studentId: reportInfo?.report?.studentId
      });

      const subjectsData = response?.data?.result;
      setSubjects(subjectsData);

      if (subjectsData && subjectsData.length > 0) {
        setActiveItem({
          subjectId: subjectsData[0].subjectId,
          activeTab: subjectsData[0].subjectName
        });
      }
      const resp = await getOverAllProgress({
        isDated: endDate,
        studentId: reportInfo?.report?.studentId
      });
      if (resp) setAllProgress(resp?.data);
    };
    if (reportInfo) {
      getSubjects();
    }
    console.log("allProgress?.studentProgress.overallProgress", allProgress);
  }, [reportInfo]);

  const getReport = async (id: string | number) => {
    setLoading(true);
    const res = await getStudentReport(id);
    if (res) {
      setReportInfo(res?.data);
      // getSubjects();
      setLoading(false);
    }
  };

  const formatDate = (dateString: any) => {
    if (!dateString) return ""; // Handle potential null/undefined values
    return new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(new Date(dateString));
  };

  const endDate: string = formatDate(reportInfo?.report?.endDate);

  const getStudentLevel = async () => {
    const res = await getStudentCurrentLevel({
      studentId: reportInfo?.report?.studentId,
      subjectId: activeItem?.subjectId,
      isDated: endDate
      // studentId: 120,
      // subjectId: 43,
    });
    if (res) setCurrentProgress(res?.data.result);
  };

  const getStudentUnArchive = async () => {
    setLoadingTarget(true);
    const resp = await getStudentUnArchiveTarget({
      studentId: reportInfo?.report?.studentId,
      subjectId: activeItem?.subjectId,
      date: endDate,
      levelId: currentProgress.levelId
    });

    if (resp) setCurrentTarget(resp?.data.unAchivments);
    setLoadingTarget(false);
    const res = await getStudentReportsById({
      id: reportInfo?.report?.studentId,
      role: "student"
    });
    if (res) setReportList(res.data.current);
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (currentTarget || []).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // Handle page change
  const handlePageChange = (newPage: any) => setCurrentPage(newPage);

  // const getSubjects = async ()=>{
  //   const res = await getStudentSubjects(user?.id);
  //   if(res){
  //     console.log("student subjects res",res);
  //     setSubjects(res?.subjects);
  //     setSelectedSubject(res?.subjects[0]?.subjectId);
  //     getSubjectLevel(res?.subjects[0]?.subjectId);
  //   }
  // }

  // const getSubjectLevel = async (subjectId: number | string) => {
  //   if (reportInfo?.report?.classId) {
  //     const res = await getLevelProgress(
  //       Number(reportInfo?.report?.classId),
  //       Number(subjectId),
  //       StudentId || user?.id
  //     );

  //   }
  // };

  return checkRoutePermissions("StudentReport") ? (
    <>
      {loading ? (
        <div className="report_generate_spin">
          <Spin style={{ fontSize: 40 }} />
        </div>
      ) : (
        <div className="mainDiv responsive">
          <div className="std_reports_main">
            <AiOutlineHome
              color="#26252A"
              fontSize={"23px"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
            <div className="std_reports_text" onClick={() => navigate(-1)}>
              Reports
            </div>
            {/* <div className="std_reports_head_btn">Jan 6, 2024 - Jan 26, 2024</div> */}
            <div className="std_reports_head_btn">
              {moment(reportInfo?.report?.endDate)?.format("MMM DD, YYYY")}
            </div>
          </div>
          <div className="report_std_head">
            <div>
              <div className="report_std_head_text">
                {reportInfo?.report?.title}
              </div>
              <div className="report_std_head_text_normal">
                Review and manage the schools data.
              </div>
            </div>
            <div className="select_btn_student_parent">
              <Select
                placeholder="Previous Submitted Reports"
                className="select_btn_student"
                onChange={(e) => setChangeReport(e)}
                options={reportList?.map((item: any) => {
                  return {
                    value: item?.reportId,
                    label: `${item?.title}`
                  };
                })}
              />
            </div>
          </div>
          <StudentProfile
            image={
              reportInfo?.report?.imageUrl
                ? reportInfo?.report?.imageUrl
                : avtar
            }
            name={`${reportInfo?.report?.firstName} ${reportInfo?.report?.lastName}`}
            classIndex={reportInfo?.report?.classIndex}
          />
          <div className="std_speedo_head">
            <div className="std_speedo_head_child ">
              <div className="report_level">Level</div>
              {/* <div className="Custom_tabs_containers">
                {subjects?.map((tab: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        activeItem?.activeTab === tab.subjectName
                          ? "custom_active_tab"
                          : "custom_tab"
                      } custom-tab-all `}
                      onClick={() =>
                        setActiveItem({
                          subjectId: tab.subjectId,
                          activeTab: tab.subjectName,
                        })
                      }
                    >
                      {tab.subjectName}
                    </div>
                  );
                })}
              </div> */}
              <div
                className="std_speedo_head_child1 std_speedo_head_childs "
                //  style={{user?.role == "student"?width:"100%" :""}}
              >
                {/* <p>Level at the time of report submission: - {(Number(reportInfo?.currentLevel?.progress) * 100).toFixed(0)}</p> */}
                {allProgress && (
                  <div
                    style={{
                      minWidth: "340px",
                      marginTop: 20,
                      height: "203px"
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        marginTop: "10px",
                        color: "black",
                        textAlign: "center",
                        marginBottom: "10px"
                      }}
                    >
                      OverAll Level{" "}
                      <span>
                        at the time of Report:{" "}
                        {Number(
                          allProgress?.studentProgress.overallProgress
                        ).toFixed(0)}
                        %
                      </span>
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        marginTop: "10px",
                        color: "black",
                        textAlign: "center",
                        marginBottom: "10px"
                      }}
                    >
                      Current Level:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {currentProgress?.levelName} -
                        {Number(
                          allProgress?.studentProgress.overallProgress
                        ).toFixed(0)}
                        %
                      </span>
                    </p>
                    {/* {currentProgress && (
                      <>
                        {" "}
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "10px",
                            color: "black",
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          Current Level:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {currentProgress?.levelName} -{" "}
                            {Number(currentProgress?.percentage).toFixed(
                              0
                            )}
                            %
                          </span>
                        </p>
                      
                      </>
                    )} */}
                    <NeedleMeterChart
                      percentage={allProgress?.studentProgress.overallProgress}
                    />
                  </div>
                  // <SpeedoMeter
                  //   data={currentProgress?.levelName}
                  //   value={Number(
                  //     (
                  //       (Number(currentProgress?.percentage) * 100) /
                  //       100
                  //     ).toFixed(0)
                  //   )}
                  //   maxValue={100}
                  // />
                )}
              </div>
            </div>
            {/* {user?.role == "student" && ( */}
            <div className="std_speedo_head_child ">
              <div className="report_level">
                Level at the time of submission
              </div>
              <div
                className="std_speedo_head_childs"
                style={{ border: "none" }}
              >
                <div className="Custom_tabs_containers">
                  {subjects?.map((tab: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          activeItem?.activeTab === tab.subjectName
                            ? "activeTab"
                            : "inactive_tab"
                        } custom-tab-all `}
                        onClick={() =>
                          setActiveItem({
                            subjectId: tab.subjectId,
                            activeTab: tab.subjectName
                          })
                        }
                      >
                        {tab.subjectName}
                      </div>
                    );
                  })}
                </div>
                <div className="std_speedo_head_child1">
                  {currentProgress && (
                    <div
                      className="leaning_card"
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <SubjectLevel
                        percent={Number(
                          (
                            (Number(currentProgress?.percentage) * 100) /
                            100
                          ).toFixed(0)
                        )}
                        level="Level"
                        link="#"
                        btnData="10% vs last month"
                        btn={true}
                        currentLevel={currentProgress?.levelName}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
          {/* //////// Target to React  ///////// */}
          <div className="learning_tab table_container ">
            <TargetToReach
              report={true}
              loading={loadingtarget}
              tableData={currentItems.map((item: any) => ({
                name: item?.levelName,
                curriculum: item?.lessonName
              }))}
              // total={currentItems?.length}
            />
            <div className="main_pagination">
              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                total={currentTarget?.length}
              />
            </div>
          </div>

          {/* //////////////// Historical Level */}
          <div className="tab_learning_header" style={{ padding: "10px" }}>
            <h1>Teacher Comments:</h1>
            <p>{reportInfo?.report?.overview}</p>
          </div>

          <div>
            {reportInfo?.report?.effort && (
              <GeneratorCard
                name="Effort /focus"
                view={true}
                value={reportInfo?.report?.effort}
              />
            )}
            {reportInfo?.report?.attendance && (
              <GeneratorCard
                name="Attendance"
                view={true}
                value={reportInfo?.report?.attendance}
              />
            )}
            {reportInfo?.report?.punctuality && (
              <GeneratorCard
                name="Punctuality"
                view={true}
                value={reportInfo?.report?.punctuality}
              />
            )}
            {reportInfo?.report?.uniform && (
              <GeneratorCard
                name="Uniform"
                view={true}
                value={reportInfo?.report?.uniform}
              />
            )}
            {reportInfo?.report?.planner && (
              <GeneratorCard
                name="Planner"
                view={true}
                value={reportInfo?.report?.planner}
              />
            )}
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      <NotAccess />
    </>
  );
};

export default StudentReport;
