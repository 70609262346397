"use client";
import GHeader from "../../components/GeneralHeader/GHeader";
import CustomCalendar from "../../components/CustomCalendar/customCalendar";
import YearlyCalendar from "../../components/CustomCalendar/yearlyCalendar";

const CalendarPage = () => {
  return (
    <div className="mainDiv responsive">
      <GHeader heading="Calendar" />  
      <YearlyCalendar />
    </div>
  );
};

export default CalendarPage;
