import { useEffect, useState } from "react";

import { Collapse, Modal, Spin } from "antd";

import Clock from "../../assets/Clock.png";
import MaterialIcon from "../../assets/material-icon.png";
import PlanIcon from "../../assets/plan-icon.png";
import ResourceIcon from "../../assets/resource-icon.png";
import LinkIcon from "../../assets/link-icon.png";
import ObjectiveIcon from "../../assets/objective-icon.png";
import { GrAttachment } from "react-icons/gr";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { GoHome } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { getSingleLesson } from "../../store/services/curriculum";
import { LessonType } from "../../types/interface/curriculum";
import { FiEdit2 } from "react-icons/fi";
import TargetModel from "../Modals/targetModal/targetModals";
import { useAppSelector } from "../../store/hooks";

import { AiOutlineAim } from "react-icons/ai";
import { FaCompress, FaExpand } from "react-icons/fa";

const { Panel } = Collapse;

const LessonDetails = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { id: lessonId } = useParams();
  const navigate = useNavigate();
  const [lessonsData, setLessonData] = useState<LessonType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isDataUpdate, setIsDataUpdate] = useState<boolean>(false);
  const location = useLocation();
  const { isTeacher } = location.state || {};
  const { currentLevelData, currentModuleId, data } = location.state || {};
  const [isFullscreen, setIsFullscreen] = useState(false);
  const allDocs: any = [];
  const [selectedDocIndex, setSelectedDocIndex] = useState<number | null>(null);

  const [isFullscreenModalVisible, setIsFullscreenModalVisible] =
    useState(false);

  const handleFullscreenToggle = (index: number) => {
    setSelectedDocIndex(index);
    setIsFullscreen(true);

    setIsFullscreenModalVisible((prev) => !prev);
  };
  const myHeader = (state: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          onClick={() => handleFullscreenToggle(state)}
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
            padding: "5px",
            fontSize: "16px"
          }}
        >
          {isFullscreen ? <FaCompress /> : <FaExpand />}
        </button>
      </div>
    );
  };
  const _isTeacher = user?.role === "teacher" ? true : false;

  useEffect(() => {
    if (
      !currentLevelData ||
      (!currentModuleId && typeof currentModuleId !== "number")
    ) {
      if (isTeacher) {
        navigate("/targets");
      } else {
        navigate("/curriculum");
      }
    }
  }, [currentLevelData, currentModuleId]);

  useEffect(() => {
    lessonId && getData();
  }, [isDataUpdate]);

  const getData = async () => {
    !isLoading && setIsLoading(true);
    const res: any = await getSingleLesson({ lessonId });
    if (res) {
      setLessonData(res);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="_lessonDetailBox">
        <div className="_breadcrumb">
          <GoHome
            size={20}
            onClick={() => navigate("/")}
            className="cursor-pointer"
          />{" "}
          <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
          <span onClick={() => navigate(-1)}>
            {user?.role === "teacher" ? "Targets" : "Curriculum"}
          </span>{" "}
          <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
          {currentLevelData?.level && currentModuleId && (
            <span
              className="cursor-pointer"
              onClick={() =>
                !_isTeacher &&
                navigate(`/curriculum`, {
                  state: data
                })
              }
            >
              {data?.subjectName}
            </span>
          )}{" "}
          <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
          {currentLevelData?.level && currentModuleId && (
            <span
              className="cursor-pointer"
              onClick={() =>
                !_isTeacher &&
                navigate(`/curriculum`, {
                  state: data
                })
              }
            >
              {currentLevelData?.level} /{" "}
              {
                currentLevelData.modules.find(
                  (module: any) => module.moduleId == currentModuleId
                )?.name
              }
            </span>
          )}{" "}
          <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
          {lessonsData?.lessonName && <p>{lessonsData?.lessonName}</p>}
        </div>

        {isLoading ? (
          <div className="_isLoading">
            <Spin />
          </div>
        ) : (
          <>
            {lessonsData ? (
              <>
                <h2>{lessonsData?.lessonName}</h2>
                <p>Review and manage the targets.</p>

                <h3>About the target</h3>

                <div className="_durationSection">
                  <div className="_durationBox">
                    <img src={Clock} alt="" className="imgclock" />
                    <p>
                      {/* Lesson Duration: {lessonsData?.duration}{" "}
                      {lessonsData?.unit === "m"
                        ? "minutes"
                        : lessonsData?.unit} */}
                      Lesson Duration: 1 Lesson
                    </p>
                  </div>
                  <FiEdit2
                    size={25}
                    className="cursor-pointer"
                    onClick={() => setIsUpdateModalVisible(true)}
                  />
                </div>
                <Collapse
                  className="collaps_1"
                  accordion
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <IoIosArrowDown
                      size={20}
                      color="rgba(102, 112, 133, 1)"
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header={"Lesson Objective"}
                    key="1"
                    extra={<img src={ObjectiveIcon} alt="Icon" />}
                  >
                    <div className="list">{lessonsData?.objective}</div>
                  </Panel>
                </Collapse>
                <Collapse
                  className="collaps_1"
                  accordion
                  expandIcon={({ isActive }) => (
                    <IoIosArrowDown
                      size={20}
                      color="rgba(102, 112, 133, 1)"
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header={"Long Term Aim"}
                    key="1"
                    extra={<AiOutlineAim size={22} color="rgb(132 135 143)" />}
                  >
                    <div className="list">
                      {lessonsData?.longTermAim || "-"}
                    </div>
                  </Panel>
                </Collapse>
                <Collapse
                  className="collaps_1"
                  accordion
                  expandIcon={({ isActive }) => (
                    <IoIosArrowDown
                      size={20}
                      color="rgba(102, 112, 133, 1)"
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header={"Materials Needed"}
                    key="1"
                    extra={<img src={MaterialIcon} alt="Icon" />}
                  >
                    <div className="list">
                      {lessonsData?.materials?.map((v) => {
                        return (
                          <div className="list_item" key={v?.materialId}>
                            <div />
                            <p>{v?.material}</p>
                          </div>
                        );
                      })}
                    </div>
                  </Panel>
                </Collapse>
                <Collapse
                  className="collaps_1"
                  accordion
                  expandIcon={({ isActive }) => (
                    <IoIosArrowDown
                      size={20}
                      color="rgba(102, 112, 133, 1)"
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header="Plan"
                    key="1"
                    extra={<img src={PlanIcon} alt="Icon" />}
                  >
                    <div className="list">
                      {lessonsData?.plans?.map((v) => {
                        return (
                          <div className="list_item" key={v?.planId}>
                            <div />
                            <p>{v?.plan}</p>
                          </div>
                        );
                      })}
                    </div>
                  </Panel>
                </Collapse>
                <Collapse
                  className="collaps_1"
                  accordion
                  expandIcon={({ isActive }) => (
                    <IoIosArrowDown
                      size={20}
                      color="rgba(102, 112, 133, 1)"
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header="Resources"
                    key="1"
                    extra={<img src={ResourceIcon} alt="Icon" />}
                  >
                    <div className="list">
                      {lessonsData?.resources?.map((v) => {
                        return (
                          <div className="list_item" key={v?.resourceId}>
                            <div />
                            <p>{v?.resource}</p>
                          </div>
                        );
                      })}
                    </div>
                  </Panel>
                </Collapse>
                <Collapse
                  className="collaps_1"
                  accordion
                  expandIcon={({ isActive }) => (
                    <IoIosArrowDown
                      size={20}
                      color="rgba(102, 112, 133, 1)"
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header="Links"
                    key="1"
                    extra={<img src={LinkIcon} alt="Icon" />}
                  >
                    <div className="list">
                      {lessonsData?.links?.map((v) => {
                        return (
                          <div className="list_item" key={v?.linkId}>
                            <div />
                            {/* <p>{v?.link}</p> */}

                            {v?.link ? (
                              <a
                                href={v?.link}
                                style={{ paddingLeft: 10 }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Linked File
                              </a>
                            ) : (
                              <p>No File Available</p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Panel>
                </Collapse>
                <Collapse
                  className="collaps_1"
                  accordion
                  expandIcon={({ isActive }) => (
                    <IoIosArrowDown
                      size={20}
                      color="rgba(102, 112, 133, 1)"
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header="Attach Files"
                    key="1"
                    extra={<GrAttachment color="gray" />}
                  >
                    <div className="list">
                      {lessonsData?.attachedFile?.map(
                        (v: any, index: number) => {
                         
                          

                          return (
                            <div>
                             
                              <div
                               className="list_item"
                                style={{ paddingBottom: 20 }}
                                key={v?.linkId}
                              >
                                <div />

                                {v?.attachedfile ? (
                                                                    

                                  <>
                                    <a
                                      style={{ paddingLeft: 10 }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={v?.attachedfile}
                                    >
                                      {" "}
                                      {v?.name ? v?.name : v.attachedfile}
                                    </a>
                                  
                                  </>
                                ) : (
                                 
                                  <p>No File Available</p>
                                )}
                              </div>
                             
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Panel>
                </Collapse>
              </>
            ) : (
              <div className="_emptyData">
                <p>No data found</p>
              </div>
            )}
          </>
        )}
      </div>

      <TargetModel
        editMode={true}
        isModalOpens={isUpdateModalVisible}
        setIsModalOpens={setIsUpdateModalVisible}
        callGetData={() => setIsDataUpdate(true)}
        currentModule={`${lessonsData?.moduleId}`}
        lessonData={lessonsData}
        isTeacher={_isTeacher}
      />
    </>
  );
};

export default LessonDetails;
