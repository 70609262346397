import { notification } from "antd";
import { attachToken, privateAPI, publicAPI } from "../../config/constants";
import {
  createCurriculum,
  getCurriculum,
  createLevel,
  getCurriculumPath,
  createModule,
  updateLevels,
  updateModule,
  deletecurriculum,
} from "../interfaces/Curriculum/Curriculum";
// import {createLevel} from '../interfaces/Curriculum/Curriculum';
import store from "..";
import {
  addCurriculum,
  setCurriculum,
} from "../slices/Curriculum/curriculumSlice";
import { addLevel, setLearningPath } from "../slices/Levels/levelsSlice";
import { addModule, setLoading } from "../slices/Modules/moduleSlice";

export const uploadFiles = async (formData: FormData) => {
  try {
    const response = await privateAPI.post("/filemanager/upload", formData);
    if (response) {
      notification.success({ message: "Upload File successfully" });
    }

    return response.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const createFolderAPI = async (formData: FormData) => {
  try {
    const response = await privateAPI.post("/filemanager/create-folder", formData);
    if (response) {
      notification.success({ message: "Upload File successfully" });
    }

    return response.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getFiles = async (search ?: string,parentId ?: string) => {
  try {
    const res = await privateAPI.get(`/filemanager/get-all?search=${search}&parentId=${parentId}`);
    return res.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getFolders = async (parentId ?: string) => {
  try {
    const res = await privateAPI.get(`/filemanager/get-all-folders?parentId=${parentId}`);
    return res.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const deleteFileFrom = async (payload: any) => {
  try {
    const response = await privateAPI.post("/filemanager/delete", payload);
    if (response) {
      notification.success({ message: "Upload deleted successfully" });
    }

    return response.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

