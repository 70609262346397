import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos
} from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCurrentDate,
  setCurrentWeekDay,
  setUpcomingClasses
} from "../../store/slices/dashboardSlice/teacherDashboardSlice";
import { dateFormat } from "../../utils";
import { getTeacherSchedule } from "../../store/services/schedule";
import { getSchduleByTeacher } from "../../store/services/dashboard";
import store from "../../store";

interface Props {
  classId?: any;
}

const WeekCalendar: React.FC<Props> = ({ classId }: any) => {
  const { currentWeekDay, currentDate } = useAppSelector(
    (state) => state.teacherDashboard
  );
  const getDate = new Date();
  const CurrentMonth = getDate.toLocaleString("default", { month: "long" });
  const CurrentYear = getDate.getFullYear();
  const user = useAppSelector((state) => state.auth.user);
  const [weekDays, setWeekDays] = useState<any>([]);
  const [currentMonth, setCurrentMonth] = useState<any>([CurrentMonth]);

  const [currentYear, setCurrentYear] = useState<any>([CurrentYear]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const _days = generateWeekDays();
    setWeekDays(_days);
  }, [currentWeekDay]);

  const generateWeekDays = () => {
    return Array.from({ length: 7 }, (_, i) =>
      moment(currentWeekDay).clone().startOf("week").add(i, "days")
    );
  };

  const fetchSchedule = async (date: string) => {
    try {
      const res = await getSchduleByTeacher({
        date: date,
        teacherId: Number(user?.id),
        classId: classId
      });

      store.dispatch(setUpcomingClasses(res?.data.scheduleList));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (currentDate) {
      fetchSchedule(currentDate);
    }
  }, [currentDate]);

  const updateWeekAndMonthYear = (direction: "next" | "prev") => {
    const newWeekStart = moment(currentWeekDay)
      .clone()
      .startOf("week")
      [direction === "next" ? "add" : "subtract"](1, "week")
      .startOf("week");

    // Update currentWeekDay with the new week
    dispatch(setCurrentWeekDay(newWeekStart.format(dateFormat)));

    // Update month and year
    const newMonth = newWeekStart.format("MMMM");
    const newYear = newWeekStart.format("YYYY");

    setCurrentMonth(newMonth); // Add this dispatch to update the month
    setCurrentYear(newYear); // Add this dispatch to update the year
  };

  return (
    <div className="_weekCalendarContainer">
      <div className="_weekCalendarHeader">
        <MdOutlineArrowBackIos
          color="rgba(102, 112, 133, 1)"
          style={{ cursor: "pointer" }}
          onClick={() => updateWeekAndMonthYear("prev")}
        />
        <p>{`${currentMonth} ${currentYear}`}</p>

        <MdOutlineArrowForwardIos
          color="rgba(102, 112, 133, 1)"
          style={{ cursor: "pointer" }}
          onClick={() => updateWeekAndMonthYear("next")}
        />
      </div>
      <div className="_dateBox">
        {weekDays?.map((v: Date, i: number) => {
          return (
            <div
              key={i}
              className={`_dateCard ${
                moment(v).format(dateFormat) === currentDate
                  ? "_activeDate"
                  : ""
              }`}
              onClick={() => {
                dispatch(setCurrentDate(moment(v).format(dateFormat)));
              }}
            >
              <p>{moment(v).format("dddd").slice(0, 1)}</p>
              <div>
                <p>{moment(v).format("DD")}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeekCalendar;
