import store from "..";
import { privateAPI } from "../../config/constants";
import { notification } from "antd";
import { setLoading, setSettings, setLogo, setFavicon } from "../slices/settingSlice";

export const getSettings = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/settings/get-settings");
    if (response) {
      store.dispatch(setSettings(response?.data?.data));
    }
    return response?.data?.data;
  } catch (error: any) {
    console.log("GET SETTINGS ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const userUpdateProfile = async (payload: any) => {

  try {
    console.log("payload is in this api call is",payload);
    // store.dispatch(setLoading(true));
    const response = await privateAPI.post("/admin/update-profile", payload);
    if (response) {
      notification.success({
        message: "Profile Updated",
      });
      return response
    }
  } catch (error: any) {
    console.log("PROFILE UPDATE ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  } finally {
    // store.dispatch(setLoading(false));
  }
};

export const userUpdatePassword = async (payload: any) => {
  try {
    // store.dispatch(setLoading(true));
    const response = await privateAPI.post("/admin/update-password", payload);
    if (response.status === 200) {
      notification.success({
        message: "Password Updated",
      });
      return response
    }
  } catch (error: any) {
    console.log("PASSWORD UPDATE ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  } finally {
    // store.dispatch(setLoading(false));
  }
};


export const UploadImage = async (data: any, message: string) => {
  try {
    const res = await privateAPI.post("/settings/add-logo", data);
    console.log("res", res);
    if (res.status === 200) {
      notification.success({ message: `${message} updated successfully` })
      return res;
    } else {
      notification.error({ message: "failed to upload image" });
    }
  } catch (error) {
    console.log("logo error", error);
  }
}

export const getImage = async () => {
  try {
    const res = await privateAPI.get("/settings/get-logo");
    if (res?.data?.logo?.length > 0) {
      store.dispatch(setLogo(res?.data?.logo[0].logo));
      store.dispatch(setFavicon(res?.data?.logo[0].favIcon));
    }
  } catch (error) {
    console.log("logo get error", error);
    notification.error({
      message: "Failed to fetch image",
    });
  }
};