"use client";

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Spin,
  Upload,
  Modal,
  Form,
  Popover,
  Empty,
  Select,
} from "antd";
import GHeader from "../../components/GeneralHeader/GHeader";
import { CiSearch } from "react-icons/ci";
import { LuUpload , LuFolderPlus } from "react-icons/lu";
import { InboxOutlined } from "@ant-design/icons";
import {
  uploadFiles,
  createFolderAPI,
  getFiles,
  getFolders,
  deleteFileFrom,
} from "../../store/services/filemanager";
import imgIcon from "../../assets/defaultFile.png";
import pdfIcon from "../../assets/pdf_icon.png";
import moment from "moment";
import { SlOptionsVertical } from "react-icons/sl";
import { RiDeleteBinLine } from "react-icons/ri";
import { PiDownloadSimple } from "react-icons/pi";
import { useAppSelector } from "../../store/hooks";

import { FaRegEye } from "react-icons/fa";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { firstLetterCap } from "../../utils";
import { PiFolders } from "react-icons/pi";
import { FaRegFileImage } from "react-icons/fa";
import { FaRegFileVideo } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";
import { FaRegFileAlt } from "react-icons/fa";
import { GrFormNext } from "react-icons/gr";

interface FormValues {
  name: string;
  parent: string;
  file: any;
}

const docType = [
  "pdf",
  "doc",
  "docx",
  "txt",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "csv",
  "tsv",
];
const imgType = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "svg",
  "webp",
  "tiff",
  "jfif",
];
const videoType = [
  "mp4",
  "mkv",
  "avi",
  "mov",
  "wmv",
  "flv",
  "webm",
  "mpeg",
  "3gp",
];

const FileManager = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFolderModalOpen, setFolderIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [allFoldes, setAllFoldes] = useState([]);
  
  const [refatch, setRefatch] = useState(false);
  const [parentId, setParentId] = useState('');
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const { Dragger } = Upload;
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState<any>(null);
  const _isAdmin = user?.role === "admin" ? true : false;
  const [history, setHistory] = useState([
    {
      name:'main',
      parent: parentId ,
    }
  ]);

  const handleOpenChange = (newOpen: any) => {
    setOpen(open === newOpen ? false : newOpen);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showFolderModal = () => {
    setFolderIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFolderCancel = () => {
    setFolderIsModalOpen(false);
  };

  const onFinish = async (values: FormValues) => {
    // console.log("🚀 ~ onFinish ~ values:", values);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("parent", values?.parent || parentId);
    formData.append("pictures", values.file.file);
    let response = await uploadFiles(formData);

    if (response) {
      setIsModalOpen(false);
      form.resetFields();
      setRefatch(!refatch);
      setDisable(false);
    }
    setIsLoading(false);
  };
  const createFolder = async (values: FormValues) => {
    // console.log("🚀 ~ onFinish ~ values:", values);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("parent", parentId);
    let response = await createFolderAPI(formData);

    if (response) {
      setFolderIsModalOpen(false);
      form.resetFields();
      setRefatch(!refatch);
      setDisable(false);
    }
    setIsLoading(false);
  };
  const fetchFiles = async () => {
    try {
      setIsMainLoading(true)
      let response = await getFiles(search,parentId);
      setAllFiles(response.data);
      setIsMainLoading(false)
    } catch (e) {
      setIsMainLoading(false)
      console.log("🚀 ~ fetchFiles ~ e:", e);
    }
  };
  // const fetchFolders = async () => {
  //   try {
  //     let response = await getFolders(parentId);
  //     setAllFoldes(response.data);
  //   } catch (e) {
  //     console.log("🚀 ~ fetchFiles ~ e:", e);
  //   }
  // };
  const DeleteFile = async (id: string) => {
    try {
      let response = await deleteFileFrom({ id });
      setRefatch(!refatch);
    } catch (e) {
      console.log("🚀 ~ fetchFiles ~ e:", e);
    }
  };
  const handleHistory = (item : any) => {
    let temp = [...history];
    
    let isAlreadyExist = temp?.findIndex((i) => i.parent === item.parent)
    if(isAlreadyExist === -1){
      let tempItem = {
        name:item?.name,
        parent:item?.fileId
      }
      setParentId(item?.fileId)
      temp.push(tempItem)
      setHistory(temp)
    }else{
      let remaining = temp.slice(0,isAlreadyExist+1)
      setParentId(item?.parent)
      setHistory(remaining)

    }
  }

  useEffect(() => {
    fetchFiles();
  }, [refatch, search,parentId]);
  // useEffect(() => {
  //   fetchFolders()
  // }, [refatch,parentId]);

  const setFileIconByType = (url: string,type: string) => {
    let fileChunks = url.split(".");
    let fileType = fileChunks[fileChunks.length - 1];

    let icon: any = <IoDocumentOutline className="svgIcon" color="red" />;
    if(type !== "folder"){
      if (docType.includes(fileType)) {
        icon = <FaRegFileAlt className="svgIcon" color="blue" />;
      } else if (imgType.includes(fileType)) {
        icon = <FaRegFileImage className="svgIcon" color="green" />;
      } else if (videoType.includes(fileType)) {
        icon = <FaRegFileVideo className="svgIcon" color="orange" />;
      } else {
        icon = <IoDocumentOutline className="svgIcon" color="red" />;
      }
    }else{
      icon = <PiFolders className="svgIcon" color="brown" />
    }
    return icon;
  };

  const PopOverContent = (item: any) => {
    return (
      <div className="side_options_popover">
        {item?.type !== "folder" && 
        <a
          href={item?.filelink}
          target="_blank"
          rel="noreferrer"
          className="side_options_popover_option"
        >
          <PiDownloadSimple size={24} /> <div>download file</div>
        </a>
        }
        {_isAdmin  ? (
          <div
            className="side_options_popover_option delete_clr"
            onClick={() => DeleteFile(item?.fileId)}
          >
            <RiDeleteBinLine size={24} color="red" /> <div>delete file</div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  };

  const handleOpenPreview = (data: any) => {
    setPreview({
      uri: data?.filelink,
      fileType: checkFileType(data?.filelink),
      fileName: data?.name,
    });
  };
  const handleClosePreview = () => {
    setPreview(null);
  };

  const checkFileType = (url: any) => {
    let fileChunks = url.split(".");
    let fileType = fileChunks[fileChunks.length - 1];
    return fileType;
  };

  return (
    <>
      <div className="mainDiv responsive">
        <GHeader
          heading="File Manager"
          headingText="Upload files, pdf, videos, pictures etc."
        />
        <div className="fliters_container">
          <Row gutter={[20, 20]} className="file_manager_header">
            <Col xs={24} lg={_isAdmin ? 16 : 24}>
              <Input
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
                prefix={
                  <CiSearch
                    style={{ width: "20px", height: "20px", color: "#667085" }}
                  />
                }
                className="filter_search"
              />
            </Col>
            {_isAdmin ? (
              <Col xs={24} sm={12} lg={4}>
                <div className="fm_upload_btn">
                  <button
                    className="openbtn"
                    style={{ border: 0, background: "none" }}
                    type="button"
                    onClick={showModal}
                  >
                    <LuUpload color="#000" size={20} /> Upload New
                  </button>
                </div>
              </Col>
            ) : (
              false
            )}
            {_isAdmin ? (
              <Col xs={24} sm={12} lg={4}>
                <div className="fm_upload_btn">
                  <button
                    className="openbtn"
                    style={{ border: 0, background: "none" }}
                    type="button"
                    onClick={showFolderModal}
                  >
                    <LuFolderPlus color="#000" size={20} /> Create Folder
                  </button>
                </div>
              </Col>
            ) : (
              false
            )}
              {
                history?.length > 1 ?
                  <Col xs={24} className="history-line">
                  {
                    history?.map((item:any,ind:number) => {
                      return(
                        <div key={ind} className="history-item" onClick={()=> handleHistory(item)} >
                          <div className={item?.parent === parentId ? "active history-item-name" : "history-item-name"} >{item?.name}</div>
                          {ind !== history?.length - 1 && <GrFormNext size={20} /> }
                        </div>
                      )
                    })
                  }
                  </Col>
                : false
              }
          </Row>
        </div>
        <div className="main" style={{ height: "auto" }}>
          <Row className="file_grid">
            {isMainLoading ?
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <div className="_isLoading">
                  <Spin />
                </div>
              </Col>
            : allFiles?.length ? (
                  allFiles.map((item?: any, ind?: number) => {
                    return (
                      <Col xs={12} sm={8} lg={4} key={ind} className="file_box">
                        <div className="file_box_header">
                          {/* <Image
                            src={setFileIconByType(item?.filelink)}
                            alt="file"
                            height={30}
                            width={30}
                          /> */}
                          {setFileIconByType(item?.filelink,item?.type)}
                          <p>{item?.name}</p>
                        </div>
                        <div className="file_box_body" onClick={()=> item?.type === "folder" ? handleHistory(item) : false}>
                          {
                            item?.type === "file" && 
                            <div
                              className="previewBox"
                              onClick={() => handleOpenPreview(item)}
                            >
                              <FaRegEye /> <p>Preview</p>
                            </div>
                          }
                          {setFileIconByType(item?.filelink,item?.type)}
                          {/* <img
                            src={setFileIconByType(item?.filelink)}
                            alt="file"
                            height={100}
                            width={100}
                          /> */}
                        </div>
                        <div className="file_box_footer">
                          <div>
                            {moment(item?.uploadedAt).format("MMMM DD, YYYY")}
                          </div>
                          <Popover
                            content={() => PopOverContent(item)}
                            title="Action"
                            trigger="click"
                            open={open === item?.fileId}
                            onOpenChange={() => handleOpenChange(item?.fileId)}
                            className="side_options"
                          >
                            <SlOptionsVertical size={16} />
                          </Popover>
                        </div>
                      </Col>
                    );
                  })
              )
            : (
              <Col xs={24} className="Empty-fileManager">
                <Empty description="This Folder is Empty" />
              </Col>
            )}
          </Row>
        </div>
      </div>

      <Modal
        title="Upload files."
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="file_manager_modal"
      >
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="file Name"
            className="labels"
            rules={[{ required: true, message: "Please input the file name!" }]}
          >
            <Input placeholder="Enter Name" className="labelinput" />
          </Form.Item>
          {/* <Form.Item
            name="parent"
            label="Select Folder"
            className="labels"
          >
            <Select
              placeholder="Select Folder"
              optionFilterProp="children"
              options={allFoldes?.map((v: any) => {
                return {
                  value: v?.fileId,
                  label: v?.name
                };
              })}
            />
          </Form.Item> */}
          <Form.Item
            name="file"
            label="File"
            className="labels"
            rules={[{ required: true, message: "Please upload the file!" }]}
          >
            <Dragger
              name="file"
              className="file_modal_upload"
              beforeUpload={() => false}
              multiple={false}
              disabled={disable}
              onChange={(info) =>
                info.file ? setDisable(true) : setDisable(false)
              }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Support for a single upload.</p>
            </Dragger>
          </Form.Item>
          <div className="modal_footer">
            <Button
              className="cancelbtn modal_footer_btn"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              htmlType="submit"
              className="addbtn modal_footer_btn"
            >
              Upload
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Upload Folder."
        open={isFolderModalOpen}
        onCancel={handleFolderCancel}
        footer={false}
        className="file_manager_modal"
      >
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={createFolder}
        >
          <Form.Item
            name="name"
            label="folder Name"
            className="labels"
            rules={[{ required: true, message: "Please input the folder name!" }]}
          >
            <Input placeholder="Enter Folder Name" className="labelinput" />
          </Form.Item>
          <div className="modal_footer">
            <Button
              className="cancelbtn modal_footer_btn"
              onClick={handleFolderCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              htmlType="submit"
              className="addbtn modal_footer_btn"
            >
              Create
            </Button>
          </div>
        </Form>
      </Modal>

      {!!preview && (
        <Modal
          title={`Preview - ${firstLetterCap(preview?.fileName)}`}
          open={!!preview}
          onCancel={handleClosePreview}
          footer={false}
          className="previewModal"
          width={1000}
          centered
        >
          {docType.includes(preview?.fileType) && (
            <DocViewer
              documents={[preview]}
              config={{ header: { disableHeader: true } }}
              pluginRenderers={DocViewerRenderers}
              style={{ height: "100%" }}
            />
          )}
          {imgType.includes(preview?.fileType) && (
            <div className="imagePreview">
              <img src={preview?.uri} alt={preview?.fileName} />
            </div>
          )}
          {videoType.includes(preview?.fileType) && (
            <div className="videoPreview">
              <video controls autoPlay>
                <source src={preview?.uri} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default FileManager;
