import progresstracker from "../../assets/Blogo.png";
import acedemicHat from "../../assets/acedemic-hat.png";
import AsSabeelLogo from "../../assets/AsSabeel-Logo-long.png";

const LoginHeader = () => {
  return (
    <div className="login-header">
      <div className="logo">
        <div className="logo-div">
          {/* <img src={progresstracker} className="changeForResponsive" alt="" /> */}
          <div className="newLogoDiv" style={{ display: "flex" }}>
            <img src={AsSabeelLogo} className="" alt="" />

            {/* <h3>Assabeel Tracker</h3> */}
          </div>
          <img src={AsSabeelLogo} className="responsiveLogo" alt="" />
        </div>
      </div>
    </div>
  );
};

export default LoginHeader;
