import React, { useEffect, useState } from "react";
import NotAccess from "../NotAccess";
import { checkRoutePermissions } from "../../utils/permission";
import "../../styles/pages/learning.css";
import { Progress, Table } from "antd";
import SubjectLevel from "../../components/subjectLevel/SubjectLevel";
import TargetToReach from "../../components/targetToReach/TargetToReach";
import HistoricalLevel from "../../components/HistoricalLevel/HistoricalLevel";
import {
  getLearningByYear,
  getLevelProgress,
  getpredictionLevel,
  getTargetsToReachNextLevel
} from "../../store/services/learning";
import { useAppSelector } from "../../store/hooks";
import { getSubjectsStudent } from "../../store/services/student";
import { getAllSubject } from "../../store/services/subject";
import {
  getStudentCurrentLevel,
  getStudentUnArchiveTarget
} from "../../store/services/report";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { getStudentLatestAchievedTargets } from "../../store/services/student";
import TargetCard from "../../components/TargetCard";
import Loading from "../../components/Loading/Loading";
const Learning = () => {
  const { user }: any = useAppSelector((state) => state?.auth);
  // console.log("user", user);
  const [year, setYear] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [activeSubject, setActiveSubject] = useState<any>();
  const [currentLevelProgress, setCurrentLevelProgress] = useState<any>(null);
  // const [targets, setTargets] = useState<any>(null);
  const [yearlyProgress, setYearlyProgress] = useState<any>(null);
  const [targetsLoading, setTargetLoading] = useState<boolean>(false);
  const [latestAchiveLoading, setLatestAchiveLoading] = useState<boolean>(false);
  const [currentLevelLoading, setCurrentLevelLoading] =
    useState<boolean>(false);
  const [yearlyProgressLoading, setYearlyProgressLoading] =
    useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState<any>(null);
  const [currentTarget, setCurrentTarget] = useState<any>([]);
  // const [pastUnmetTarget, setPastUnmetTarget] = useState<any>([]);
  const [latestAchievesTargets, setLatestAchievedTargets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // useEffect(() => {
  //   console.log("currentProgress?????", currentProgress);
  //   console.log("currentLevelProgress", currentLevelProgress);
  // }, [currentLevelProgress]);

  useEffect(() => {
    if (subjects && subjects.length > 0) {
      setActiveSubject(subjects[0]);
    }
  }, [subjects]);

  useEffect(() => {
    const getSubjects = async () => {
      try {
        const res: any = await getSubjectsStudent({ studentId: user?.id });
        setSubjects(res?.result);
      } catch (err) {
        console.log(err);
      }

      // console.log("res", res);
    };
    getSubjects();
  }, []);

  useEffect(() => {
    const getLearning = async () => {
      // console.log("res is???????????")

      try {
        setYearlyProgressLoading(true);
        const res = await getLearningByYear(activeSubject.subjectId, user?.id);
        // console.log("res is????????<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>", res);

        if (res) {
          setYearlyProgress(res?.progress);
          setYearlyProgressLoading(false);
        }
      } catch (err) {
        console.log("error is in getLearning is", err);
        setYearlyProgressLoading(false);
      }
    };
    if (activeSubject) {
      getLearning();
    }
  }, [year, activeSubject]);

  useEffect(() => {
    const getCurrentLevelProgress = async () => {
      try {
        setCurrentLevelLoading(true);
        const res = await getpredictionLevel({
          studentId: user?.id,
          subjectId: activeSubject.subjectId
        });
        if (res) {
          // console.log("res.predict",res.predict);
          setCurrentLevelProgress(res?.predict);
          setCurrentLevelLoading(false);
        }
      } catch (err) {
        console.log("Error in getCurrentLevelProgress is ", err);
        setCurrentLevelLoading(false);
      }
    };

    const getTargets = async () => {
      try {
        setTargetLoading(true);
        const res = await getStudentUnArchiveTarget({
          studentId: user?.id,
          subjectId: activeSubject?.subjectId
        });
        if (res === undefined) setTargetLoading(false);
        if (res) {
          setCurrentTarget(res?.data.unAchivments);
          setTargetLoading(false);
        }
      } catch (err) {
        setTargetLoading(false);
      }
    };

    const getLatestAchivedTargets = async () => {
      try {
        setLatestAchiveLoading(true);
        const res = await getStudentLatestAchievedTargets({
          studentId: user?.id,
          classId: user?.ClassId,
          subjectId : activeSubject?.subjectId
        });
        if (res === undefined) setLatestAchiveLoading(false);
        if (res) {
          setLatestAchievedTargets(res?.lastestAchivments);
          setLatestAchiveLoading(false);
        }
      } catch (err) {
        setLatestAchiveLoading(false);
      }
    };
    if (activeSubject) {
      getCurrentLevelProgress();
      getTargets();
      getStudentLevel();
      getLatestAchivedTargets();
    }
  }, [activeSubject]);

  const getStudentLevel = async () => {
    const res = await getStudentCurrentLevel({
      // studentId: reportInfo?.report?.studentId,
      // subjectId: activeItem?.subjectId,

      studentId: user?.id,
      subjectId: activeSubject?.subjectId
    });

    if (res) setCurrentProgress(res?.data.result);
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (currentTarget || []).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // Handle page change
  const handlePageChange = (newPage: any) => setCurrentPage(newPage);

  return checkRoutePermissions("learningPage") ? (
    <div className="mainDiv responsive">
      <h2 className="head_learning">Learning</h2>
      <p className="learning_text">Review and manage the schools data.</p>
      <div className="leaning_parent">
        {subjects?.length > 0 &&
          subjects?.map((item: any) => {
            return (
              <div
                key={item.subjectId}
                onClick={() => {
                  setActiveSubject(item);
                  // setKey(item.subjectId);
                  // setName(item.subjectName);
                }}
                className={`btn_leaning ${
                  item.subjectId === activeSubject?.subjectId
                    ? "btn_leaning_active"
                    : ""
                }`}
              >
                {item.subjectName}
              </div>
            );
          })}
      </div>
      <div className="learing_Child">{activeSubject?.subjectName}</div>
      {/* /////     subject level   */}
      <div className="leaning_card">
        <SubjectLevel
          // percent={Number(
          //   ((Number(currentProgress?.percentage) * 100) / 100).toFixed(0)
          // )}
          percent={Number(Number(currentProgress?.percentage).toFixed(0))}
          level="Current level"
          link="#"
          btnData="10% vs last month"
          btn={true}
          currentLevel={currentProgress?.levelName}
          loading={currentLevelLoading}
        />
        <SubjectLevel
          percent={
            Number(currentLevelProgress?.percentage) === 0
              ? 100
              : Number(
                  (Number(currentLevelProgress?.percentage)).toFixed(0)
                )
          }
          // currentLevel="Level 2"
          currentLevel={
            Number(currentProgress?.percentage) === 0
              ? currentLevelProgress?.currentLevel
              : currentLevelProgress?.progressLevel
          }
          level="Target Level By End Of Year"
          loading={currentLevelLoading}
        />
      </div>

      {/* //////// Target to React  ///////// */}
      <div className="table_container learning_tab">
        <TargetToReach
          loading={targetsLoading}
          report={false}
          // nextLevel={targets?.nextLevel?.levelName}
          tableData={currentItems.map((item: any) => ({
            name: item?.levelName,
            curriculum: item?.lessonName
          }))}
        />
        <div className="main_pagination">
          <CustomPagination
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
            total={currentTarget?.length}
          />
        </div>
      </div>
      <div style={{padding:'16px'}} >
        <div className="_latestAchievedTargets">
          <div className="_targetHeader">
            <h2>Recently Met Targets</h2>
          </div>
          <div className="_targetBody">
            {latestAchiveLoading ? (
              <>
                <br />
                <Loading />
              </>
            ) : (
              latestAchievesTargets?.map((v, i) => {
                return <TargetCard key={i} data={v} index={i} />;
              })
            )}
          </div>
        </div>
      </div>

      {/* //////////////// Historical Level */}
      <div className="tab_learning_header">
        <HistoricalLevel
          year={year}
          setYear={setYear}
          progress={yearlyProgress}
          loading={yearlyProgressLoading}
          studentId={user?.id}
        />
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Learning;
