import { privateAPI, publicAPI } from "../../config/constants";
import { notification } from "antd";

const handleError = (error: any) => {
  notification.error({
    message: error?.response?.data?.message || "Server Error",
  });
};

export const calendarDayTypes = async () => {
  try {
    const response = await privateAPI.get("/calender/day-types");
    return response?.data
   
  } catch (error: any) {
    handleError(error);
    return error?.response?.data?.message;
  }
};
export const getCalendarEvents = async (date:any) => {
    try {
      const response = await privateAPI.get(`/calender/calendar-events?date=${date}`);
   
    return response?.data?.data
    } catch (error: any) {
      handleError(error);
      return error?.response?.data?.message;
    }
  };

  export const sendCalendarEvents = async (payload:any) => {
    try {
      const response = await privateAPI.post("/calender/calendar-events",payload);
  
    return response
    } catch (error: any) {
      handleError(error);
      return error?.response?.data?.message;
    }
  };
  export const updateCalendarEvents = async (payload:any) => {
    try {
      const response = await privateAPI.post("/calender/update-calendar-events",payload);
  
    return response
    } catch (error: any) {
      handleError(error);
      return error?.response?.data?.message;
    }
  };
  export const deleteCalendarEvents = async (id:any) => {
    try {
      const response = await privateAPI.delete(`/calender/calendar-events/${id}`);
  
    return response
    } catch (error: any) {
      handleError(error);
      return error?.response?.data?.message;
    }
  };