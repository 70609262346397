// import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { FaEye, FaEdit, FaPlus, FaTrash, FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import "./yearlyCalendar.css";
// import { calendarDayTypes, deleteCalendarEvents, getCalendarEvents, sendCalendarEvents, updateCalendarEvents } from "../../store/services/Calendar";
// import { Modal, Button, Input } from "antd";
// import { useAppSelector } from "../../store/hooks";
// import { Dayjs } from "dayjs";

// // Define props interface
// interface YearlyCalendarProps {
//   year?: number;
// }

// const YearlyCalendar: React.FC<YearlyCalendarProps> = ({ year = moment().year() }) => {
//   const { user } = useAppSelector((state) => state.auth);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [selectedDate, setSelectedDate] = useState<string | null>(null);
//   const [legendType, setLegendType] = useState<any | null>(null);
//   const [legends, setLegends] = useState<[]>([]);
//   const [calendarEvents, setCalendarEvents] = useState<any>([]);
//   const [comment, setComment] = useState("");
//   const [currentDate, setCurrentDate] = useState<any>("");
//   const [selectedEvent, setSelectedEvent] = useState<any>(null);
//   const [modalType, setModalType] = useState("add"); // add, edit, view, delete
//   const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
//   const [dayId, setDayId] = useState("");
//   const [currentYear, setCurrentYear] = useState(year); // Manage current year state
//   const [hoveredDay, setHoveredDay] = useState<string | null>(null);

//   const months: string[] = [
//     "September", "October", "November", "December",
//     "January", "February", "March", "April"
//   ];

//   const eventDataValidation = selectedDate && legendType;

//   const getDayBackgroundColor = (day: string) => {
//     const eventForDay = calendarEvents.find((event: any) => {
//       return moment(event.eventDate).format("YYYY-MM-DD") === day;
//     });
//     if (eventForDay) {
//       return `rgba(${eventForDay.day_type_color}, 0.7)`; // Assuming `day_type_color` is an RGB string
//     }
//     return ''; // Default background for days with no events
//   };

//   const getCalendarDayTypes = async () => {
//     const response = await calendarDayTypes();
//     if (response) setLegends(response?.data);
//   };

//   const GetCalendarEvents = async () => {
//     const response = await getCalendarEvents(currentYear); // Use currentYear for fetching events
//     if (response) setCalendarEvents(response);
//   };

//   const SetCalendarEvents = async () => {
//     try {
//       if (legendType?.dayId && selectedDate) {
//         const response = await sendCalendarEvents({
//           dayId: legendType?.dayId,
//           eventDate: selectedDate,
//           comment: comment
//         });
//         if (response) {
//           handleModalClose();
//           GetCalendarEvents();
//         }
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   const UpdateCalendarEvents = async () => {
//     const dayId = legends
//       ?.filter((item: any) => item?.name === legendType?.name)
//       ?.map((item: any) => item?.dayId);

//     try {
//       if (selectedEvent?.eventId && legendType?.dayId) {
//         const response = await updateCalendarEvents({
//           id: selectedEvent?.eventId,
//           dayId: dayId[0],
//           event_date: selectedDate,
//           comment: comment
//         });
//         if (response) {
//           handleModalClose();
//           GetCalendarEvents();
//         }
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   const DeleteCalendarEvents = async () => {
//     try {
//       if (selectedEvent?.eventId) {
//         const response = await deleteCalendarEvents(selectedEvent?.eventId);
//         if (response) {
//           setIsDeleteModalVisible(false);
//           handleModalClose();
//           GetCalendarEvents();
//         }
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   useEffect(() => {
//     getCalendarDayTypes();
//     GetCalendarEvents(); // Fetch events based on currentYear
//   }, [currentYear]); // Refetch events when currentYear changes

//   const handleModalClose = () => {
//     setIsModalVisible(false);
//     setIsDeleteModalVisible(false);
//     setSelectedDate(null);
//     setLegendType(null);
//     setComment("");
//     setModalType("add");
//     setSelectedEvent(null);
//   };

//   const handleDateClick = (date: any, event: React.MouseEvent) => {
//     setSelectedDate(date.format("YYYY-MM-DD"));
//     if (event.target instanceof HTMLElement && event.target.closest(".onHover-buttons")) {
//       return; // Do nothing if an icon is clicked (i.e., avoid triggering date click)
//     }
//     if (user?.role === "admin") setIsModalVisible(true);
//   };

//   const handleIconClick = (event: any, type: string) => {
//     if (type === "add") {
//       setSelectedEvent(null);
//       setLegendType(null);
//       setComment("");
//       setModalType(type);
//       setIsModalVisible(true);
//     } else {
//       setSelectedEvent(event);
//       setSelectedDate(moment(event.eventDate).format("YYYY-MM-DD"));
//       setLegendType({
//         dayId: event.eventId,
//         name: event.day_type_name,
//         color: event.day_type_color
//       });
//       setComment(event.comment);
//       setModalType(type);

//       if (type === "delete") {
//         setIsDeleteModalVisible(true);
//       } else {
//         setIsModalVisible(true);
//       }
//     }
//   };

//   const handlePreviousYear = () => {
//     setCurrentYear(currentYear - 1); // Go to the previous year
//   };

//   const handleNextYear = () => {
//     setCurrentYear(currentYear + 1); // Go to the next year
//   };

//   return (
//     <div className="yearly-calendar">
//       <div className="year-navigation">
//         <button onClick={handlePreviousYear}>
//           <FaArrowLeft />
//         </button>
//         <span>{currentYear}</span>
//         <button onClick={handleNextYear}>
//           <FaArrowRight />
//         </button>
//       </div>

//       {months.map((month, idx) => {
//         const actualYear = idx >= 4 ? currentYear + 1 : currentYear;
//         const monthIndex = idx >= 4 ? idx - 4 : idx + 8;
//         const start = moment(`${actualYear - 1}-${monthIndex + 1}-01`, "YYYY-MM-DD");
//         const end = start.clone().endOf("month");

//         const days = [];
//         for (let day = start.clone(); day.isBefore(end) || day.isSame(end, "day"); day.add(1, "day")) {
//           days.push(day.clone());
//         }

//         return (
//           <div key={month} className="month">
//             <h2>{month} {actualYear}</h2>
//             <div className="weekdays">
//               {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
//                 <div key={i}>{d}</div>
//               ))}
//             </div>
//             <div className="days">
//               {days.map((day) => {
//                 const formattedDate = day.format("YYYY-MM-DD");
//                 const bgColor = getDayBackgroundColor(formattedDate);

//                 return (
//                   <div
//                     key={formattedDate}
//                     className="day"
//                     onMouseEnter={() => setHoveredDay(formattedDate)}
//                     onMouseLeave={() => setHoveredDay(null)}
//                     onClick={(e) => handleDateClick(day, e)}
//                     style={{ backgroundColor: bgColor }} // Apply background color based on event
//                   >
//                     {day.format("D")}
//                     {bgColor !== '' && hoveredDay === formattedDate && (
//                       <div className="tooltip">
//                         <button
//                           onClick={() => handleIconClick(day, "view")}
//                           className="tooltip-button"
//                         >
//                           <FaEye />
//                         </button>
//                         <button
//                           onClick={() => handleIconClick(day, "edit")}
//                           className="tooltip-button"
//                         >
//                           <FaEdit />
//                         </button>
//                         <button
//                           onClick={() => handleIconClick(day, "add")}
//                           className="tooltip-button"
//                         >
//                           <FaPlus />
//                         </button>
//                         <button
//                           onClick={() => handleIconClick(day, "delete")}
//                           className="tooltip-button"
//                         >
//                           <FaTrash />
//                         </button>
//                       </div>
//                     )}
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         );
//       })}
//       <Modal
//         title={modalType === "add" ? "Add Event" : modalType === "edit" ? "Edit Event" : modalType === "view" ? "View Event" : "Event Details"}
//         open={isModalVisible}
//         onCancel={handleModalClose}
//         footer={[
//           <div className="calendar-madal-footer">
//             <Button key="close" onClick={handleModalClose}>
//               Close
//             </Button>
//             {modalType === "add" && (
//               <Button
//                 disabled={eventDataValidation ? false : true}
//                 style={{ backgroundColor: "#26252a", color: "#ffffff" }}
//                 onClick={SetCalendarEvents}
//               >
//                 Submit
//               </Button>
//             )}
//             {modalType === "edit" && (
//               <Button
//                 style={{ backgroundColor: "#26252a", color: "#ffffff" }}
//                 onClick={UpdateCalendarEvents}
//               >
//                 Update
//               </Button>
//             )}
//           </div>
//         ]}
//       >
//         {selectedDate && <p>{selectedDate}</p>}

//         <div style={{ marginTop: 20 }}>
//           <div>
//             <h1>{modalType === "view" ? "Event Details" : "Select Event Type"}</h1>
//           </div>
//           {legends?.map((option: any) => (
//             <div className="typeSelectOptionContainer">
//               <div className="modalLegendOptionsMain">
//                 <div
//                   key={option.dayId}
//                   onClick={() => (
//                     modalType === "view" ? null : setLegendType(option),
//                     setDayId(option?.id)
//                   )}
//                   className="modalLegendOptionsContainer"
//                 >
//                   <div
//                     className="modalLegendOptions"
//                     style={{
//                       backgroundColor: `rgba(${option.color},0.7)`,
//                       border:
//                         legendType?.name === option.name
//                           ? "2px solid black"
//                           : "1px solid transparent"
//                     }}
//                   />
//                   {option.name}
//                 </div>
//               </div>
//             </div>
//           ))}
//           <div style={{ marginTop: 20 }}>
//             <h1>Comments</h1>
//             {modalType === "view" ? (
//               <div
//                 style={{
//                   backgroundColor: "#fff",
//                   padding: "10px",
//                   borderRadius: "4px",
//                   border: "1px solid #000000",
//                   minHeight: "80px",
//                   whiteSpace: "pre-wrap",
//                 }}
//               >
//                 {comment}
//               </div>
//             ) : (
//               <Input.TextArea
//                 rows={4}
//                 placeholder="Add a comment"
//                 value={comment}
//                 onChange={(e) =>
//                   modalType === "view" ? null : setComment(e.target.value)
//                 }
//                 disabled={modalType === "view"}
//               />
//             )}
//           </div>
//         </div>
//       </Modal>

//       <Modal
//         title="Confirm Delete"
//         open={isDeleteModalVisible}
//         onOk={DeleteCalendarEvents}
//         onCancel={handleModalClose}
//       >
//         <p>Are you sure you want to delete this event?</p>
//       </Modal>
//     </div>
//   );
// };

// export default YearlyCalendar;

import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  FaEye,
  FaEdit,
  FaPlus,
  FaTrash,
  FaArrowLeft,
  FaArrowRight
} from "react-icons/fa";
import "./yearlyCalendar.css";
import {
  calendarDayTypes,
  deleteCalendarEvents,
  getCalendarEvents,
  sendCalendarEvents,
  updateCalendarEvents
} from "../../store/services/Calendar";
import { Modal, Button, Input } from "antd";
import { useAppSelector } from "../../store/hooks";
import { Dayjs } from "dayjs";

interface YearlyCalendarProps {
  year?: number;
}

const YearlyCalendar: React.FC<YearlyCalendarProps> = ({
  year = moment().year()
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [legendType, setLegendType] = useState<any | null>(null);
  const [legends, setLegends] = useState<[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<any>([]);
  const [comment, setComment] = useState("");
  const [currentDate, setCurrentDate] = useState<any>("");
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [modalType, setModalType] = useState("add");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [dayId, setDayId] = useState("");
  const [currentYear, setCurrentYear] = useState(year);
  const [hoveredDay, setHoveredDay] = useState<string | null>(null);
  const [eventHover, setEventHover] = useState<string | null>(null); // Add state for event hover

  const months: string[] = [
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August"
  ];

  const eventDataValidation = selectedDate && legendType;

  // const getDayBackgroundColor = (day: string) => {
  //   const eventForDay = calendarEvents.find((event: any) => {
  //     return moment(event.eventDate).format("YYYY-MM-DD") === day;
  //   });
  //   if (eventForDay) {
  //     return `rgba(${eventForDay.day_type_color}, 0.7)`;
  //   }
  //   return "";
  // };

  const getDayBackgroundColor = (day: string) => {
    // Check for the starting date (September 1st)

    if (
      moment(day).format("YYYY-MM-DD") ===
      moment(`${currentYear - 1}-09-01`).format("YYYY-MM-DD")
    ) {
      return "#A6F1E0"; // Green background for September 1st
    }

    // Check for the ending date (August 31st)
    if (moment(day).isSame(moment(`${currentYear}-08-31`), "day")) {
      return "#EB5A3C"; // Red background for August 31st
    }

    const eventForDay = calendarEvents.find((event: any) => {
      return moment(event.eventDate).format("YYYY-MM-DD") === day;
    });

    if (eventForDay) {
      return `rgba(${eventForDay.day_type_color}, 0.7)`;
    }
    return "";
  };

  const getCalendarDayTypes = async () => {
    const response = await calendarDayTypes();
    if (response) setLegends(response?.data);
  };

  const GetCalendarEvents = async () => {
    const response = await getCalendarEvents(currentYear);
    if (response) setCalendarEvents(response);
  };

  const SetCalendarEvents = async () => {
    try {
      if (legendType?.dayId && selectedDate) {
        const response = await sendCalendarEvents({
          dayId: legendType?.dayId,
          eventDate: selectedDate,
          comment: comment
        });
        if (response) {
          handleModalClose();
          GetCalendarEvents();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const UpdateCalendarEvents = async () => {
    const dayId = legends
      ?.filter((item: any) => item?.name === legendType?.name)
      ?.map((item: any) => item?.dayId);

    try {
      if (selectedEvent?.eventId && legendType?.dayId) {
        const response = await updateCalendarEvents({
          id: selectedEvent?.eventId,
          dayId: dayId[0],
          event_date: selectedDate,
          comment: comment
        });
        if (response) {
          handleModalClose();
          GetCalendarEvents();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const DeleteCalendarEvents = async () => {
    try {
      if (selectedEvent?.eventId) {
        const response = await deleteCalendarEvents(selectedEvent?.eventId);
        if (response) {
          setIsDeleteModalVisible(false);
          handleModalClose();
          GetCalendarEvents();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCalendarDayTypes();
    GetCalendarEvents();
  }, [currentYear]);

  const handleModalClose = () => {
    setIsModalVisible(false);
    setIsDeleteModalVisible(false);
    setSelectedDate(null);
    setLegendType(null);
    setComment("");
    setModalType("add");
    setSelectedEvent(null);
  };

  const handleDateClick = (date: any, event: React.MouseEvent) => {
    setSelectedDate(date.format("YYYY-MM-DD"));

    if (
      event.target instanceof HTMLElement &&
      event.target.closest(".event-item")
    ) {
      return;
    }

    if (user?.role === "admin")
      if (modalType !== "delete") {
        setIsModalVisible(true);
      }
  };

  const handleIconClick = (event: any, type: string, e: React.MouseEvent) => {
    setSelectedDate(moment(event.eventDate).format("YYYY-MM-DD"));

    e.stopPropagation();
    if (type === "add") {
      setSelectedEvent(null);
      setLegendType(null);
      setComment("");
      setModalType(type);
      setIsModalVisible(true);
    } else {
      setSelectedEvent(event);
      setLegendType({
        dayId: event.eventId,
        name: event.day_type_name,
        color: event.day_type_color
      });
      setComment(event.comment);
      setModalType(type);

      if (type === "delete") {
        setIsDeleteModalVisible(true);
      } else {
        setIsModalVisible(true);
      }
    }
  };

  const handlePreviousYear = () => {
    setCurrentYear(currentYear - 1);
  };

  const handleNextYear = () => {
    setCurrentYear(currentYear + 1);
  };

  return (
    <>
      <div className="year-navigation">
        {/* <button className="yearChangeArrowButton" onClick={handlePreviousYear}> */}
        <FaArrowLeft onClick={handlePreviousYear} size={20} color="#fff" />
        {/* </button> */}
        <span className="currentYear">{currentYear}</span>
        {/* <button className="yearChangeArrowButton" onClick={handleNextYear}> */}
        <FaArrowRight onClick={handleNextYear} color="#fff" size={20} />
        {/* </button> */}
      </div>
      <div className="leftSideBox">
        <div className="syllabusYearDuration">
          <div className="syllabusYears">
            <div
              className="legendsColorBox"
              style={{ backgroundColor: "#A6F1E0" }}
            ></div>
            <p>Start Year:{currentYear - 1}</p>{" "}
          </div>
          <div className="syllabusYears">
            <div
              className="legendsColorBox"
              style={{ backgroundColor: "#EB5A3C" }}
            ></div>
            <p>End Year:{currentYear}</p>{" "}
          </div>
        </div>
        <div className="legendContainer">
          {legends?.map((option: any) => (
            <div key={option?.dayId} className="legendPoints">
              <span
                className="legendsColorBox"
                style={{
                  backgroundColor: `rgba(${option.color},0.7)`
                }}
              />
              {option.name}
            </div>
          ))}
        </div>
      </div>
      <div className="yearly-calendar">
        {months.map((month, idx) => {
          const actualYear = idx >= 4 ? currentYear : currentYear - 1;
          const monthIndex = idx >= 4 ? idx - 4 : idx + 8;
          const start = moment(
            `${actualYear}-${monthIndex + 1}-01`,
            "YYYY-MM-DD"
          );
          const end = start.clone().endOf("month");
          const firstDayOfWeek = start.day();

          const days = [];
          for (let i = 0; i < firstDayOfWeek; i++) {
            days.push(null);
          }
          for (
            let day = start.clone();
            day.isBefore(end) || day.isSame(end, "day");
            day.add(1, "day")
          ) {
            days.push(day.clone());
          }

          return (
            <div key={month} className="month">
              <h2>
                {month} {actualYear}
              </h2>
              <div className="weekdays">
                {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
                  <div key={i}>{d}</div>
                ))}
              </div>
              <div className="days">
                {days.map((day, index) => {
                  if (!day) {
                    return (
                      <div
                        key={index}
                        className="day"
                        style={{ opacity: 0 }}
                      ></div>
                    );
                  }
                  const formattedDate = day.format("YYYY-MM-DD");
                  const bgColor = getDayBackgroundColor(formattedDate);
                  const isEvent =
                    bgColor !== "" &&
                    bgColor !== "#EB5A3C" &&
                    bgColor !== "#A6F1E0";

                  // Find events for this day
                  const eventsForDay = calendarEvents.filter(
                    (event: any) =>
                      moment(event.eventDate).format("YYYY-MM-DD") ===
                      formattedDate
                  );

                  return (
                    <div
                      key={formattedDate}
                      className="day"
                      onMouseEnter={() => setHoveredDay(formattedDate)}
                      onMouseLeave={() => setHoveredDay(null)}
                      onClick={(e) => handleDateClick(day, e)}
                      style={{ backgroundColor: bgColor }}
                    >
                      {day.format("D")}
                      {isEvent && hoveredDay === formattedDate && (
                        <div className="tooltip">
                          {/* Display all events for the hovered day */}
                          {eventsForDay.map((event: any) => (
                            <div
                              key={event.eventId}
                              className="event-item"
                              onMouseEnter={() => setEventHover(event.eventId)}
                              onMouseLeave={() => setEventHover(null)}
                            >
                              <p className="eventOnhover">
                                {event.day_type_name}
                              </p>
                              {eventHover === event.eventId && (
                                <div className="commentBox">
                                  {user?.role === "admin" && (
                                    <div style={{ width: "100%" }}>
                                      <p style={{ color: "black" }}>Comment</p>
                                      <p
                                       onClick={(e) =>
                                        handleIconClick(event, "view", e)
                                      }
                                        className="ellipsis-text"
                                        style={{
                                          color: "black",
                                          border: "1px solid black",
                                          borderRadius: 10,
                                          marginTop: 5,
                                          padding: "10px 20px"
                                        }}
                                       
                                      >
                                        {event.comment ? event?.comment : "---"}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}

                              {eventHover === event.eventId && (
                                <>
                                  <p
                                    style={{ paddingBottom: 5, color: "black" }}
                                  >
                                    {" "}
                                    Options
                                  </p>
                                  <div className="onHover-button">
                                    <button
                                      onClick={(e) =>
                                        handleIconClick(event, "view", e)
                                      }
                                      className="tooltip-button"
                                    >
                                      <FaEye color="black" />
                                    </button>
                                    {user?.role === "admin" && (
                                      <>
                                        <button
                                          onClick={(e) =>
                                            handleIconClick(event, "edit", e)
                                          }
                                          className="tooltip-button"
                                        >
                                          <FaEdit color="black" />
                                        </button>
                                        <button
                                          onClick={(e) =>
                                            handleIconClick(event, "add", e)
                                          }
                                          className="tooltip-button"
                                        >
                                          <FaPlus color="black" />
                                        </button>
                                        <button
                                          onClick={(e) =>
                                            handleIconClick(event, "delete", e)
                                          }
                                          className="tooltip-button"
                                        >
                                          <FaTrash color="black" />
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <Modal
          title={
            modalType === "add"
              ? "Add Event"
              : modalType === "edit"
              ? "Edit Event"
              : modalType === "view"
              ? "View Event"
              : "Event Details"
          }
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <div className="calendar-madal-footer">
              <Button key="close" onClick={handleModalClose}>
                Close
              </Button>
              {modalType === "add" && (
                <Button
                  disabled={eventDataValidation ? false : true}
                  style={{ backgroundColor: "#26252a", color: "#ffffff" }}
                  onClick={SetCalendarEvents}
                >
                  Submit
                </Button>
              )}
              {modalType === "edit" && (
                <Button
                  style={{ backgroundColor: "#26252a", color: "#ffffff" }}
                  onClick={UpdateCalendarEvents}
                >
                  Update
                </Button>
              )}
            </div>
          ]}
        >
          {selectedDate && <p>{selectedDate}</p>}

          <div style={{ marginTop: 20 }}>
            <div>
              <h1>
                {modalType === "view" ? "Event Details" : "Select Event Type"}
              </h1>
            </div>
            {legends?.map((option: any) => (
              <div className="typeSelectOptionContainer">
                <div className="modalLegendOptionsMain">
                  <div
                    key={option.dayId}
                    onClick={() => (
                      modalType === "view" ? null : setLegendType(option),
                      setDayId(option?.id)
                    )}
                    className="modalLegendOptionsContainer"
                  >
                    <div
                      className="modalLegendOptions"
                      style={{
                        backgroundColor: `rgba(${option.color},0.7)`,
                        border:
                          legendType?.name === option.name
                            ? "2px solid black"
                            : "1px solid transparent"
                      }}
                    />
                    {option.name}
                  </div>
                </div>
              </div>
            ))}
            <div style={{ marginTop: 20 }}>
              <h1>Comments</h1>
              {modalType === "view" ? (
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #000000",
                    minHeight: "80px",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {comment}
                </div>
              ) : (
                <Input.TextArea
                  rows={4}
                  placeholder="Add a comment"
                  value={comment}
                  onChange={(e) =>
                    modalType === "view" ? null : setComment(e.target.value)
                  }
                  disabled={modalType === "view"}
                />
              )}
            </div>
          </div>
        </Modal>
        <Modal
          title="Confirm Delete"
          open={isDeleteModalVisible}
          onOk={DeleteCalendarEvents}
          onCancel={handleModalClose}
        >
          <p>Are you sure you want to delete this event?</p>
        </Modal>{" "}
      </div>
    </>
  );
};

export default YearlyCalendar;
