import { privateAPI } from "../../config/constants";
import store from "..";
import {
  setCurrentStudent,
  setExamDetail,
  setExams,
  setIsLoading,
  setUpdatedArray,
} from "../slices/teacherExamSlice";
// import { setStudentsByClassSubject } from "../slices/students/studentSlice";
import { setStudentsByClassSubject } from "../slices/students/studentSlice";

export const getTeacherExams = async (payload: any) => {
  try {
    store.dispatch(setIsLoading("exam"));
    const res = await privateAPI.post("/exam/get-teacher-exams", payload);
    
    if (res) {
      store.dispatch(setExams(res?.data?.examList));
    }
  } catch (error) {
    console.log("Error ", error);
  } finally {
    store.dispatch(setIsLoading(""));
  }
};

export const getExamDetailByTeacher = async (payload: any) => {
  try {
    const state = store.getState();

    store.dispatch(setIsLoading("detail"));
    const res = await privateAPI.post(
      "/exam/get-exam-detail-by-teacher",
      payload
    );
    if (res) {
      store.dispatch(setExamDetail(res?.data));
      if (res?.data?.students?.length > 0) {
        //@ts-ignore
        if (!state.teacherExam.currentStudent) {
          store.dispatch(setCurrentStudent(res?.data?.students?.[0] || null));
        }
        store.dispatch(setStudentsByClassSubject(res?.data?.students));
      }
    }
    return res?.data;
  } catch (error) {
    console.log("Error ", error);
  } finally {
    store.dispatch(setIsLoading(""));
  }
};

export const updateExams = async (payload: any, submitted: any) => {
  console.log({ scores: payload, submitted: submitted });
  try {
    store.dispatch(setIsLoading("save"));
    const res = await privateAPI.post("/result/create", {
      scores: payload,
      submitted: submitted,
    });
    if (res) {
      await getExamDetailByTeacher({
        examId: store.getState()?.teacherExam?.selectExamId,
      });
      store.dispatch(setUpdatedArray([]));
    }
    return res;
  } catch (error) {
    console.log("Error ", error);
  } finally {
    store.dispatch(setIsLoading(""));
  }
};

export const getStudentExams = async (payload: any) => {
  try {
    const res = await privateAPI.post("/exam/get-student-exams", payload);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const getExamDetailsByStudent = async (data: any) => {
  try {
    store.dispatch(setIsLoading("detail"));
    const res = await privateAPI.post("/exam/get-exam-detail-by-student", data);
    if (res) {
      store.dispatch(setExamDetail(res?.data));
      console.log(">>>>>>> res", res.data);
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    store.dispatch(setIsLoading(""));
  }
};
