import React, { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import ResultCard from "../../components/ResultCard";
import TargetCard from "../../components/TargetCard";
import AvatarRoundIcons from "../../components/AvatarRoundIcons";

import { LuInfo } from "react-icons/lu";
import { BsTelephone } from "react-icons/bs";
import ProgressCard from "../../components/ProgressCard";
import SingleStudentCard from "../classesOverview/ClassesProgress/SingleStudentCard";
import { useAppSelector } from "../../store/hooks";
import NeedleMeterChart from "../../components/Chart/NeedleMeterChart";
import BarChartComp from "../../components/Chart/BarChartComp";
import {
  getSingleStudentState,
  getStudentLatestAchievedTargets,
  getStudentLatestExamResult,
  getStudentLevelBySubject,
  getStudentPerfomanceByMonth,
  getSubjectsStudent,
  fetchBadge,
} from "../../store/services/student";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
import { RiExpandUpDownLine } from "react-icons/ri";
import { getAllSubject } from "../../store/services/subject";

interface LoadingType {
  latestAchievedTargetsloading: boolean;
  latestExamResultloading: boolean;
  performanceByMonthLoading: boolean;
  currentLevelProgressLoading: boolean;
}

const StudentDashboard: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [singleStudent, setSingleStudent] = useState([]);
  const [latestExamResult, setLatestExamResult] = useState<any>([]);
  const [latestAchievesTargets, setLatestAchievedTargets] = useState([]);
  const [performanceByMonth, setPerformanceByMonth] = useState<any>([]);
  const [subjects, setSubjects] = useState<any>([]);
  const [currentLevelProgress, setCurrentLevelProgress] = useState<any>(null);
  const [badge, setBadge] = useState<string>("");
  const [selectedSubjectId, setSelectedSubjectId] = useState<number | null>(
    null
  );
  const [loading, setLoading] = useState<LoadingType>({
    latestAchievedTargetsloading: false,
    latestExamResultloading: false,
    performanceByMonthLoading: false,
    currentLevelProgressLoading: false,
  });
  let _performance = "";
  let _underperformance = "Underperformance";
  let _overperformance = "Overperformance";

  const getSubjects = async () => {
    try {
      setLoading((prevLoading) => ({
        ...prevLoading,
        currentLevelProgressLoading: true,
      }));
      console.log("ye wala bhi chala he")
      const res: any = await getSubjectsStudent({
        studentId: user?.id,
      });
      if (res) {
        setSubjects(res?.result);
        setLoading((prevLoading) => ({
          ...prevLoading,
          currentLevelProgressLoading: false,
        }));
      }
       
    } catch (err) {
      console.log(err);
      setLoading((prevLoading) => ({
        ...prevLoading,
        currentLevelProgressLoading: false,
      }));
    }
  };

  const getLatestExamResult = async () => {
    try {
      setLoading((prevLoading) => ({
        ...prevLoading,
        latestExamResultloading: true,
      }));
      const res = await getStudentLatestExamResult({
        studentId: user?.id,
      });
      if (res) setLatestExamResult(res?.results);
      setLoading((prevLoading) => ({
        ...prevLoading,
        latestExamResultloading: false,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const getLatestAchievedTargets = async () => {
    try {
      setLoading((prevLoading) => ({
        ...prevLoading,
        latestAchievedTargetsloading: true,
      }));
      const res = await getStudentLatestAchievedTargets({
        studentId: user?.id,
        classId: user?.ClassId,
      });
      if (res) setLatestAchievedTargets(res?.lastestAchivments);
      setLoading((prevLoading) => ({
        ...prevLoading,
        latestAchievedTargetsloading: false,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const getSingleStudent = async () => {
    const res = await getSingleStudentState({
      studentId: user?.id,
      classId: user?.ClassId,
    });
    if (res) setSingleStudent(res?.data?.result);
  };

  const getBadge = async () => {
    try {
      const res = await fetchBadge({
        studentId: user?.id,
      });
      if (res) setBadge(res?.badge);
    } catch (err) {
      console.log(err);
    }
  };

  const getPerformanceByMonth = async () => {
    try {
      setLoading((prevLoading) => ({
        ...prevLoading,
        performanceByMonthLoading: true,
      }));
      const res = await getStudentPerfomanceByMonth({
        studentId: user?.id,
        classId: user?.ClassId,
      });
      if (res) setPerformanceByMonth(res);
      setLoading((prevLoading) => ({
        ...prevLoading,
        performanceByMonthLoading: false,
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const getCurrentLevelBySubject = async () => {
    try {
      setLoading((prevLoading) => ({
        ...prevLoading,
        currentLevelProgressLoading: true,
      }));
      const res: any = await getStudentLevelBySubject({
        studentId: user?.id,
        subjectId: selectedSubjectId,
      });
      if (res) setCurrentLevelProgress(res?.result);
      setLoading((prevLoading) => ({
        ...prevLoading,
        currentLevelProgressLoading: false,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSubjects();
    getSingleStudent();
    getLatestExamResult();
    getLatestAchievedTargets();
    getPerformanceByMonth();
    getBadge()
  }, [user]);

  useEffect(() => {
    if (subjects?.length > 0) {
      setSelectedSubjectId(subjects[0].subjectId);
    }
  }, [subjects]);

  useEffect(() => {
    if (selectedSubjectId !== null) {
      getCurrentLevelBySubject();
    }
  }, [selectedSubjectId]);

  return (
    <div className="_studentDashboardWrapper">
      <div className="_studentDashboard">
        {/* ----------> Left Section <----------*/}
        <div className="_leftSection">
          <div className="_leftSectionWrapper">
            <h2 className="_mainHeading">
              Welcome back, {`${user?.firstName || "-"}`}{" "}
              {`${user?.LastName || "-"}`}! 👋🏼
            </h2>
            <p className="_description">Review your progress.</p>

            <div className="_studentCard">
              <SingleStudentCard student={user} overperformance />
            </div>
            {/* Chart Section */}
            <div className="_chartSection">
              <div className="_chartBox">
                <p className="_chartHeading">Level</p>
                <div className="_chart">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          backgroundColor: "var(--primary-color)",
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />
                      <span>Level</span>
                    </div>
                    <div>
                      {subjects?.length > 0 && (
                        <Select
                          className="filter_select"
                          placeholder="Select a subject"
                          optionFilterProp="children"
                          value={selectedSubjectId}
                          onChange={(e) => setSelectedSubjectId(e)}
                          suffixIcon={
                            <RiExpandUpDownLine
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#667085",
                              }}
                            />
                          }
                        >
                          {subjects.map((item: any) => {
                            return (
                              <Select.Option value={item.subjectId}>
                                {item.subjectName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                    </div>
                  </div>
                  {loading.currentLevelProgressLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "70%",
                        height: "70%",
                      }}
                    >
                      <Loading />
                    </div>
                  ) : (
                    currentLevelProgress && (
                      <>
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "15px",
                            color: "black",
                          }}
                        >
                          Current Level:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {currentLevelProgress?.levelName} -{" "}
                            {Number(currentLevelProgress.percentage).toFixed(0)}
                            %
                          </span>
                        </p>
                        <div style={{ minWidth: "340px", marginTop: 20 }}>
                          <NeedleMeterChart
                            percentage={currentLevelProgress.percentage}
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="_chartBox">
                <p className="_chartHeading">Perfomance</p>
                <div className="_chart">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {loading.performanceByMonthLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Loading />
                      </div>
                    ) : (
                      <BarChartComp data={performanceByMonth} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Latest Exam Result Section */}
            <div className="_latestExamResult">
              <div className="_resultHeader">
                <h2>Latest Exam Result</h2>
                {latestExamResult?.length > 0 && (
                  <div>
                    <img
                      src={require("../../assets/calender.png")}
                      alt="Icon"
                    />
                    <p>
                      {moment(latestExamResult[0]?.createdAt).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="_resultBody">
                <Row gutter={[20, 20]}>
                  {loading.latestExamResultloading ? (
                    <Loading />
                  ) : (
                    latestExamResult?.map((v: any, i: any) => {
                      return (
                        <Col key={i} xs={24} xl={8}>
                          <ResultCard data={v} />
                        </Col>
                      );
                    })
                  )}
                </Row>
              </div>
            </div>

            {/* Latest Achieved Targets */}
            <div className="_latestAchievedTargets">
              <div className="_targetHeader">
                <h2>Latest Achieved Targets</h2>
              </div>
              <div className="_targetBody">
                {loading.latestAchievedTargetsloading ? (
                  <>
                    <br />
                    <Loading />
                  </>
                ) : (
                  latestAchievesTargets?.map((v, i) => {
                    return <TargetCard key={i} data={v} index={i} />;
                  })
                )}
              </div>
            </div>
          </div>
          <div style={{ height: "20px" }} />
        </div>

        {/* ----------> Right Section <----------*/}
        <div className="_rightSection">
          <div className="_studentDetails">
            <h2>Student Profile</h2>
            <div className="_studentImage">
              <img
                src={
                  user?.imageUrl
                    ? user?.imageUrl
                    : require("../../assets/attachment.png")
                }
                alt={"Student"}
              />
              <AvatarRoundIcons />
            </div>
            <div className="_studentNameBox">
              <h3>
                {user?.firstName} {user?.LastName}
              </h3>
              {badge === "least" ? (
                <div className="_underperformance">
                  <LuInfo style={{ marginBottom: "-2px" }} /> Underperfomance
                </div>
              ) : badge === "top" ? (
                <div className="_overperformance">
                  <LuInfo style={{ marginBottom: "-2px" }} /> Overperfomance
                </div>
              ) : (
                ""
              )}
            </div>
            <p>Student</p>
            <div className="_classAndContact">
              <p>
                <LuInfo style={{ marginBottom: "-2px", marginRight: "3px" }} />{" "}
                {user?.className || "N/A"}
              </p>
            </div>
          </div>
          <div className="_classesProgressSection">
            <h2>Classes Progress</h2>
            <div className="_classesProgressBox">
              {singleStudent && singleStudent.length > 0 ? (
                singleStudent?.map((v, i) => {
                  return <ProgressCard key={i} data={v} />;
                })
              ) : (
                <p>No class and subject assigned</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
