import React, { useEffect, useState } from "react";
import NotAccess from "../NotAccess";
import { checkRoutePermissions } from "../../utils/permission";
import { Button, notification, Select, Spin } from "antd";
import { RiExpandUpDownLine } from "react-icons/ri";
import SingleStudentCard from "../classesOverview/ClassesProgress/SingleStudentCard";
import CustomTable from "../createAssesment/CustomTable";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import {
  getExamDetailByTeacher,
  getStudentExams,
  getExamDetailsByStudent,
  getTeacherExams,
  updateExams
} from "../../store/services/teacherExam";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setNextStudent,
  setPreStudent,
  setSelectExamId,
  setUpdatedArray
} from "../../store/slices/teacherExamSlice";
import { firstLetterCap, getAward } from "../../utils";
import store from "../../store";
import Loading from "../../components/Loading/Loading";
import moment from "moment";

const Exams = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [exam, setExam] = useState([]);
  const [resultCheck, setResultCheck] = useState(false);
  const [singleStudent, setSingleStudent] = useState(false);
  const { exams, examDetail, isLoading, currentStudent, updatedArray } =
    useAppSelector((state) => state.teacherExam);
  const [examDropdownLoad, setExamDropdownLoad] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.id && user.role === "teacher") {
      _getTeacherExams();
    }
  }, [user]);

  const _getTeacherExams = async () => {
    try {
      setExamDropdownLoad(true);
      await getTeacherExams({ teacherId: user?.id });
      setExamDropdownLoad(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  function countTotalLessons(subjects: any) {
    let totalLessons = 0;
    //@ts-ignore
    subjects.forEach((subject) => {
      //@ts-ignore
      subject.modules.forEach((module) => {
        totalLessons += module.lessons.length;
      });
    });

    return totalLessons;
  }

  const _handleSelectExam = async (e: any) => {
    if (user?.role === "student") {
      const data = {
        examId: e,
        studentId: user?.id
      };
      await getExamDetailsByStudent(data);

      dispatch(setSelectExamId(e));
      // console.log(">>>>>aaaa",res)
    } else {
      await getExamDetailByTeacher({ examId: e });
      dispatch(setSelectExamId(e));
    }
  };

  const handleSaveExam = async () => {
    try {
      const totalLessons = countTotalLessons(examDetail.subjects);
      
      if (updatedArray.length === 0) {
        notification.error({
          message: "You did not change anything to update"
        });
        return;
      }
  
      // Ensure all items in updatedArray have the assessedOnValue set to 0 if not updated
      const updatedData = updatedArray.map((item: any) => ({
        ...item,
        assessedOn: item.assessedOn === ''? 0:item.assessedOn
      }));
  
      const studentId = updatedData[0].studentId;
      const check = examDetail.results.some((item: any) => {
        return item.studentId === studentId;
      });
  
      if (!check) {
        await updateExams(updatedData, singleStudent ? true : false);
      } else {
        await updateExams(updatedData, false);
        store.dispatch(setUpdatedArray([]));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  

  // const handleSaveExam = async () => {
  //   try {
  //     const totalLessons = countTotalLessons(examDetail.subjects);
  //     if (updatedArray.length === 0) {
  //       notification.error({
  //         message: "You did not change any thing  to update"
  //       });
  //       return;
  //     }

  //     // const bool = updatedArray.some((item: any) => {
  //     //   return item.score === null;
  //     // });
  //     // if (bool) {
  //     //   notification.error({
  //     //     message: "Marks are required",
  //     //   });
  //     //   return;
  //     // }

  //     // if (updatedArray.length > 0) {
  //       const updatedData = updatedArray.map((item: any) => ({
  //         ...item,
  //         // Ensure 'assessedOnValue' is 0 if it's null or undefined
  //         assessedOnValue: item.assessedOnValue ?? 0
  //       }));
  //     const studentId = updatedArray[0].studentId;
  //     const check = examDetail.results.some((item: any) => {
  //       return item.studentId === studentId;
  //     });
  //     if (!check) {
  //       // if (totalLessons !== updatedArray.length) {
  //       //   notification.error({
  //       //     message: "Marks should be required>>>>>>>>",
  //       //   });
  //       //   return;
  //       // }
  //       await updateExams(updatedArray, singleStudent ? true : false);
  //     } else {
  //       await updateExams(updatedArray, false);
  //       store.dispatch(setUpdatedArray([]));
  //     }
  //     //}
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const fetchStudentExam = async () => {
    try {
      setExamDropdownLoad(true);
      const res: any = await getStudentExams({ studentId: user?.id });
      setExam(res?.data?.examList);
      setExamDropdownLoad(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (user?.role === "student") {
      fetchStudentExam();
    }
  }, [user]);

  useEffect(() => {
    //@ts-ignore
    const studentsWithResults =
      examDetail?.students?.filter((student: any) =>
        //@ts-ignore
        examDetail?.results?.some((result) => result?.studentId === student.id)
      ) || [];

    // //@ts-ignore
    const allStudentsInResults =
      studentsWithResults.length === examDetail?.students?.length;
    // //@ts-ignore
    const oneStudentLeft =
      studentsWithResults.length < examDetail?.students?.length;

    setResultCheck(allStudentsInResults);
    setSingleStudent(oneStudentLeft);
  }, [examDetail]);

  const getMarks = (arr: any) => {
    let totalMarks = 0;
    let obtainedMarks = 0;
    arr?.forEach((subject: any) => {
      subject?.modules?.forEach((module: any) => {
        totalMarks += module?.lessons?.length * 20;
        module?.lessons?.forEach((lesson: any) => {
          const _found = examDetail?.results?.find(
            (v: any) =>
              (v?.studentId === currentStudent?.id ||
                v?.studentId === user?.id) &&
              v?.subjectId === subject?.subjectId &&
              v?.moduleId === module?.moduleId &&
              v?.lessonId === lesson?.lessonId
          );
          obtainedMarks += _found?.score || 0;
        });
      });
    });

    let award = getAward(totalMarks, obtainedMarks);

    return { totalMarks, obtainedMarks, ...award };
  };

  return checkRoutePermissions("examsPage") ? (
    <div className="_examsContainer">
      <div className="exam_parent">
        <div>
          <h2>Exams</h2>

          {examDetail?.exam?.[0]?.examName && (
            <p className="exam_name">
              Exam Name: <b>{examDetail?.exam?.[0]?.examName}</b>
            </p>
          )}
          {examDetail?.exam?.[0]?.examDate && (
            <p className="exam_date">
              Exam Date:{" "}
              <b>
                {moment(examDetail?.exam?.[0]?.examDate).format("DD-MMM-YYYY")}
              </b>
            </p>
          )}
        </div>
        <div className="_selectBoxSection">
          {user?.role === "teacher" && (
            <Select
              loading={isLoading === "exam"}
              placeholder="Select Exam"
              optionFilterProp="children"
              onChange={_handleSelectExam}
              notFoundContent={
                examDropdownLoad ? (
                  <div style={{ margin: "5px 0px" }}>
                    <Loading />
                  </div>
                ) : undefined
              }
              suffixIcon={
                <RiExpandUpDownLine
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#667085"
                  }}
                />
              }
              options={exams?.map((v: any) => {
                return {
                  value: v?.id,
                  label: v?.examName
                };
              })}
            />
          )}
          {user?.role !== "teacher" && (
            <Select
              loading={isLoading === "exam"}
              placeholder="Select Exam"
              optionFilterProp="children"
              onChange={_handleSelectExam}
              notFoundContent={
                examDropdownLoad ? (
                  <div style={{ margin: "5px 0px" }}>
                    <Loading />
                  </div>
                ) : undefined
              }
              suffixIcon={
                <RiExpandUpDownLine
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#667085"
                  }}
                />
              }
              options={exam?.map((v: any) => {
                return {
                  value: v?.id,
                  label: v?.examName
                };
              })}
            />
          )}
        </div>
      </div>
      {
        // isLoading === "exam" ||
        isLoading === "detail" ? (
          <div className="_emptyDetail">
            <Spin />
          </div>
        ) : examDetail ? (
          <>
            {examDetail?.students?.length > 0 && (
              <div>
                <SingleStudentCard
                  student={user?.role === "student" ? user : currentStudent}
                  search
                  type={"exam"}
                  bool={user?.role === "teacher" ? false : true}
                />
              </div>
            )}
            <div className="_contentSection">
              {examDetail?.subjects?.length > 0 ? (
                <>
                  {examDetail.subjects.map((v: any, i: number) => (
                    <CustomTable key={i} customize tableData={v} />
                  ))}
                  <h2>
                    Total Marks:{" "}
                    {`${getMarks(examDetail.subjects)?.obtainedMarks}/${
                      getMarks(examDetail.subjects)?.totalMarks
                    } `}
                    {getMarks(examDetail.subjects)?.type && (
                      <small
                        className="_awardBox"
                        style={{
                          background: getMarks(examDetail.subjects)?.bgColor,
                          color: getMarks(examDetail.subjects)?.textColor
                        }}
                      >
                        ({getMarks(examDetail.subjects)?.type})
                      </small>
                    )}
                  </h2>
                </>
              ) : (
                <div className="_emptyDetail">
                  <p>No Exam</p>
                </div>
              )}

              {user?.role === "teacher" &&
                Object.keys(examDetail).length > 0 && (
                  <div className="_saveButton">
                    <Button
                      loading={isLoading === "save"}
                      disabled={isLoading === "save"}
                      type="primary"
                      onClick={handleSaveExam}
                    >
                      {resultCheck ? "Finished" : "Save"}
                    </Button>
                  </div>
                )}
            </div>
            {user?.role === "teacher" && (
              <div className="_footerNextPrevButton">
                <Button onClick={() => dispatch(setNextStudent())}>
                  <IoMdArrowBack
                    style={{ marginRight: "5px", marginBottom: "-3px" }}
                    size={17}
                  />{" "}
                  Previous Student
                </Button>
                <div className="_studentName">
                  <p>
                    {firstLetterCap(currentStudent?.firstName)}{" "}
                    {firstLetterCap(currentStudent?.lastName)}
                  </p>
                </div>
                <Button
                  onClick={() => {
                    dispatch(setPreStudent());
                    dispatch(setUpdatedArray([]));
                  }}
                >
                  Next Student
                  <IoMdArrowForward
                    style={{ marginLeft: "5px", marginBottom: "-3px" }}
                    size={17}
                  />
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="_emptyDetail">
            <p>Empty Detail</p>
          </div>
        )
      }
    </div>
  ) : (
    <NotAccess />
  );
};

export default Exams;
